import React, { useEffect } from 'react';
import { HashRouter as Router, Routes, Route } from 'react-router-dom';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
// import ProTip from './ProTip';
import Dashboard from './Dashboard';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Rankings from './Rankings';
import CollectionValues from './CollectionValues';
import FAQ from './FAQ';
// import ReactGA from 'react-ga';]
import InventoryPage from './ROICalc';
import ChangeLog from './ChangeLog';
import { lightBlue } from '@mui/material/colors';
import Deals from './Deals';
import Armory from './Armory';
import Search from './Search';
import Skin from './Skin';
import { Buffer } from 'buffer';
import CharmGradients from './CharmGradients';
import ChainTradeups from './ChainTradeups';
import TradingSiteCompare from './TradingSiteCompare';
import { HelmetProvider } from "react-helmet-async";
import CarePackagePage from './CarePackage';

window.Buffer = Buffer;



const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    action: {
      active: lightBlue[200],
      activeOpacity: 1,
      hover: lightBlue[100],
      hoverOpacity: 0.7,
      focus: lightBlue[600],
      focusOpacity: 1,
      selected: lightBlue[300],
      selectedOpacity: 1
    },
  },
  typography: {
    fontFamily: 'Barlow',
  }
});

export default function App() {


  return (
    // <Router>
    <HelmetProvider>
      <ThemeProvider theme={darkTheme}>
        <CssBaseline />

        <Routes>
          <Route path="/" Component={Rankings}>
            {/* <Rankings /> */}
          </Route>
          <Route path="/item/:collectionId" Component={Dashboard}>
            {/* <Dashboard /> */}
          </Route>
          <Route path="/collections" Component={CollectionValues}>
            {/* <Dashboard /> */}
          </Route>
          <Route path="/faq" Component={FAQ}>
            {/* <Dashboard /> */}
          </Route>
          <Route path="/inventory" Component={InventoryPage}> {/* Add this line */}
            {/* <InventoryPage /> */}
          </Route>
          <Route path="/changelog" Component={ChangeLog}> {/* Add this line */}
            {/* <InventoryPage /> */}
          </Route>
          <Route path="/deals" Component={Deals}> {/* Add this line */}
            {/* <InventoryPage /> */}
          </Route>
          <Route path="/armory" Component={Armory}> {/* Add this line */}
            {/* <InventoryPage /> */}
          </Route>
          <Route path="/search" Component={Search}> {/* Add this line */}
            {/* <InventoryPage /> */}
          </Route>
          <Route path="/skin/:skinId" Component={Skin}>
            {/* <Dashboard /> */}
          </Route>
          <Route path="/charmcolors" Component={CharmGradients}>
            {/* <Dashboard /> */}
          </Route>
          <Route path="/carepackage" Component={CarePackagePage}>
            {/* <Dashboard /> */}
          </Route>
          {/* <Route path="/chaintradeups" Component={ChainTradeups}> */}
            {/* <Dashboard /> */}
          {/* </Route> */}
          {/* <Route path="/tradingcompare" Component={TradingSiteCompare}> */}
            {/* <Dashboard /> */}
          {/* </Route> */}
        </Routes>

      </ThemeProvider>
    </HelmetProvider>
    // </Router>
  );
}
