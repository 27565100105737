import React from 'react';
import { Backdrop, Paper, Typography, IconButton, TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { BarChart, Bar, XAxis, YAxis, Tooltip } from 'recharts';
import { skinportLink, steamLink, buffLink, csfloatLink, tradeitLink } from './allLinks';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import { CaseGridItem } from './CaseGridItem';



function getWears(minFloat, maxFloat) {
    let fullFloatPercents = {};
    for (let wear of wearNames) {
        let floatRange = maxFloat - minFloat;
        let lowerRange = (wearRangeStarts[wear] ?? 0) - minFloat;
        let higherRange = (wearRanges[wear] ?? 0) - minFloat;
        let eFloatStart = lowerRange / floatRange;
        let eFloatEnd = higherRange / floatRange;
        let totalPercent = 0;
        let floatPercents = {};
        for (let wearName of wearNames) {
            let start = wearRangeStartDrops[wearName] ?? 0;
            let end = wearRanges[wearName] ?? 0;
            if (start < eFloatEnd && end > eFloatStart) {
                let realRange = end - start;
                let insideRange;
                let avgRangeFloat;
                if (eFloatStart < start && eFloatEnd > end) {
                    insideRange = end - start;
                    avgRangeFloat = (insideRange * floatRange) / 2 + minFloat + start * floatRange;
                } else if (eFloatStart > start && eFloatEnd < end) {
                    insideRange = eFloatEnd - eFloatStart;
                    avgRangeFloat = (insideRange * floatRange) / 2 + minFloat + start * floatRange;
                } else if (eFloatStart < start) {
                    insideRange = eFloatEnd - start;
                    avgRangeFloat = (insideRange * floatRange) / 2 + minFloat + start * floatRange;
                } else {
                    insideRange = end - eFloatStart;
                    avgRangeFloat = (insideRange * floatRange) / 2 + minFloat + start * floatRange;
                }
                let percentRange = insideRange / realRange;
                let percent = percentRange * wearDropRates[wearName];
                totalPercent += percent;
                floatPercents[avgRangeFloat.toFixed(4)] = percent;
            }
        }
        let floatPercentsKeys = Object.keys(floatPercents);
        let totalAvgFloat = 0;
        let totalPercents = 0;
        for (let stringPercent of floatPercentsKeys) {
            let percent = floatPercents[stringPercent];
            totalAvgFloat += (percent / totalPercent) * parseFloat(stringPercent);
            totalPercents += percent;
        }
        fullFloatPercents[wear] = (totalPercents);
    }
    return fullFloatPercents;
}

const rarity_dict = {
    0: "Consumer",
    1: "Industrial",
    2: "Mil_Spec",
    5: "Restricted",
    8: "Classified",
    11: "Covert"
}

const wearNames = [
    'Factory New',
    'Minimal Wear',
    'Field-Tested',
    'Well-Worn',
    'Battle-Scarred',
];

const wearRanges = {
    'Factory New': 0.07,
    'Minimal Wear': 0.15,
    'Field-Tested': 0.38,
    'Well-Worn': 0.45,
    'Battle-Scarred': 1,
};

const smallWearNames = {
    'Factory New': "FN",
    'Minimal Wear': "MW",
    'Field-Tested': "FT",
    'Well-Worn': "WW",
    'Battle-Scarred': "BS",
};

const wearRangeStarts = {
    'Factory New': 0,
    'Minimal Wear': 0.07,
    'Field-Tested': 0.15,
    'Well-Worn': 0.38,
    'Battle-Scarred': 0.45,
    'magic': 1, // not used, but needed for calculation
};

const wearDropRates = {
    'Factory New': 0.03,
    'Minimal Wear': 0.24,
    'Field-Tested': 0.33,
    'Well-Worn': 0.24,
    'Battle-Scarred': 0.16,
};

const wearRangeStartDrops = {
    'Factory New': 0,
    'Minimal Wear': 0.08,
    'Field-Tested': 0.16,
    'Well-Worn': 0.39,
    'Battle-Scarred': 0.46,
};

const wearCodeNames = {
    'Factory New': "FactoryNew",
    'Minimal Wear': "MinimalWear",
    'Field-Tested': "FieldTested",
    'Well-Worn': "WellWorn",
    'Battle-Scarred': "BattleScarred",
}

const realWearNames = { 'FactoryNew': 'Factory New', 'MinimalWear': 'Minimal Wear', 'FieldTested': 'Field-Tested', 'WellWorn': 'Well-Worn', 'BattleScarred': 'Battle-Scarred' };




function Overlay({ item, onClose, caseData, initialItems, investType, exchangeRate, isMobileLayout, selectedItem, currency, pricingSource, sortBy, showInvest, showProfit, showListings, showUnbox}) {
    const itemWears = getWears(caseData['Items'][item.Name]['FloatMin'] / 100, caseData['Items'][item.Name]['FloatMax'] / 100);
    const rarityName = caseData['Items'][item.Name]['Rarity'];
    const itemName = item.Name;
    const baseData = initialItems.filter((item) => item.CollectionId === selectedItem.ItemCollectionId && item.CollectionType != "Souvenir")[0]
    const MemoizedCaseGridItem = React.memo(CaseGridItem);
    // const data = Object.keys(Object.entries(itemWears).filter(([key, value]) => value !== 0)).map((key) => ({
    //   name: key,
    //   value: itemWears[key]
    // }));
    const data = Object.entries(itemWears)
        .filter(([key, value]) => value !== 0)
        .map(([key, value], index) => ({
            name: key,
            value: itemWears[key]
        }));

    const handlePaperClick = (event) => {
        // Stop propagation to prevent closing when clicking inside the paper area
        event.stopPropagation();
    };


    function formatNumber(number, isCurrency) {
        // Use the user's locale
        const userLocale = navigator.language || 'en-US';

        // Create Intl.NumberFormat with user's locale
        if (isCurrency) {
            const formatter = new Intl.NumberFormat(userLocale, {
                //   minimumFractionDigits: 1,
                style: "currency",
                currency: currency,
                maximumFractionDigits: Math.abs(number) > 1000 ? 0 : number > 100 ? 1 : 2,
            });
            return formatter.format(number);
        } else {
            const formatter = new Intl.NumberFormat(userLocale, {
                //   minimumFractionDigits: 1,
                maximumFractionDigits: Math.abs(number) > 1000 ? 0 : number > 100 ? 1 : 2,
            });
            return formatter.format(number);
        }

        // Format the number

    }

    const CustomizedLabel = (props) => {
        const { x, y, fill, value } = props;
        return (
            isMobileLayout ?
                <text x={x + 32} y={y - 10} textAnchor="middle" fontSize={16} dominantBaseline="middle" fill={"white"}>{(value * 100).toFixed(2)}%</text> :
                <text x={x + 66} y={y - 10} textAnchor="middle" fontSize={16} dominantBaseline="middle" fill={"white"}>{(value * 100).toFixed(2)}%</text>
        );
    };

    return (
        isMobileLayout ?
            <Backdrop
                sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={true}
                onClick={onClose}
            >
                <Paper sx={{ p: 1, width: '95vw', maxHeight: '95vh', position: 'relative', overflowY: 'scroll' }} onClick={handlePaperClick}>
                    <IconButton
                        sx={{ position: 'absolute', top: 8, right: 8, zIndex: 1 }}
                        onClick={onClose}
                    >
                        <CloseIcon />
                    </IconButton>
                    <div style={{ display: "flex", flexDirection: "column", width: '100%' }}>


                        <Typography variant="h5" gutterBottom style={{ margin: 'auto', width: '100%', textAlign: 'center' }}>
                            {itemName}
                        </Typography>
                        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                            <Typography variant="h6" gutterBottom style={{ margin: 'auto', width: '100%', textAlign: 'center' }}>
                                1 in {formatNumber(1 / item["ItemChance"], false)}
                            </Typography>
                            <Typography variant="h6" gutterBottom style={{
                                margin: 'auto', width: '100%', textAlign: 'center',
                                color:
                                    (caseData?.ISTradeit ?
                                        (rarityName === "Contraband" ?
                                            (typeof caseData?.KnifeEstimates?.[itemName] === "object" ?
                                                (Object.entries(caseData?.KnifeEstimates?.[itemName]).filter(([key, value]) => value === true && key.includes("StatTrak")).length * 0.1 +
                                                    Object.entries(caseData?.KnifeEstimates?.[itemName]).filter(([key, value]) => value === true && !key.includes("StatTrak")).length <
                                                    Object.entries(caseData?.KnifeEstimates?.[itemName]).filter(([key, value]) => value === false && key.includes("StatTrak")).length * 0.1 +
                                                    Object.entries(caseData?.KnifeEstimates?.[itemName]).filter(([key, value]) => value === false && !key.includes("StatTrak")).length) :
                                                (caseData?.KnifeEstimates?.[itemName] === true)
                                            ) ? "white" : "rgb(131 131 131)" :
                                            (typeof caseData?.ItemEstimates?.[rarityName]?.[itemName] === "object" ?
                                                (Object.entries(caseData?.ItemEstimates?.[rarityName]?.[itemName]).filter(([key, value]) => value === true && key.includes("StatTrak")).length * 0.1 +
                                                    Object.entries(caseData?.ItemEstimates?.[rarityName]?.[itemName]).filter(([key, value]) => value === true && !key.includes("StatTrak")).length <
                                                    Object.entries(caseData?.ItemEstimates?.[rarityName]?.[itemName]).filter(([key, value]) => value === false && key.includes("StatTrak")).length * 0.1 +
                                                    Object.entries(caseData?.ItemEstimates?.[rarityName]?.[itemName]).filter(([key, value]) => value === false && !key.includes("StatTrak")).length) :
                                                (caseData?.ItemEstimates?.[rarityName]?.[itemName] === true)
                                            ) ? "white" : "rgb(131 131 131)"
                                        ) : "white")
                            }}>
                                {formatNumber((rarityName === "Contraband" ? caseData["KnifeCosts"][itemName]['totalItemValue'] : caseData["ItemCosts"][rarityName][itemName]['totalItemValue']) / exchangeRate, true)} AVG
                            </Typography>
                        </div>
                    </div>
                    {/* <Typography variant="h6" gutterBottom>
          {itemName.description}
        </Typography> */}
                    {/* Add any additional content you want to display in the overlay */}
                    {/* <BarChart width={500} height={500} data={itemNameWears} layout="horizontal">
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis type="number" />
        <YAxis dataKey="name" type="category" />
        <Tooltip />
        <Bar dataKey="value" fill="#8884d8" label={CustomizedLabel}>
            {Object.entries(itemNameWears).map((entry, index) => (
            <Cell key={`cell-${index}`} fill={"red"} />
            ))}
        </Bar>
        </BarChart> */}
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <img src={caseData["Items"][itemName]["ImageUrl"]} style={{ height: '150px', margin: 'auto' }} />
                        <BarChart style={{ margin: 'auto' }} width={64 * data.length} height={120} data={data} layout="horizontal" barCategoryGap={1} margin={{ top: 30, right: 0, left: 0, bottom: 0 }}>
                            {/* <CartesianGrid strokeDasharray="3 3" /> */}
                            <YAxis type="number" hide />
                            <XAxis dataKey="name" type="category" tickFormatter={(tick) => smallWearNames[tick]} stroke="RGBA(255,255,255,0.8)" />
                            {/* <Tooltip /> */}
                            <Bar dataKey="value" fill="#8884d8" label={CustomizedLabel}>
                                {/* {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={"red"} />
            ))} */}
                            </Bar>
                        </BarChart>
                    </div>
                    <TableContainer component={Paper} >
                        <Table sx={{ width: '100%' }} aria-label="simple table" size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell></TableCell>
                                    {Object.entries((rarityName == "Contraband" ? caseData["KnifeCosts"][itemName] : caseData["ItemCosts"][rarityName][itemName])).filter(([key, value]) => key.includes("StatTrak")).length > 0 &&
                                        <TableCell component="th" scope="row" sx={{ color: "rgb(207, 106, 50)", textAlign: 'center', margin: '0', fontSize: '0.9rem', lineHeight: '0.8' }}>
                                            ST (10x Rarer)
                                        </TableCell>
                                    }
                                    <TableCell component="th" scope="row" sx={{ textAlign: 'center', fontSize: '0.9rem', lineHeight: '0.8' }}>
                                        Normal
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {Object.entries((rarityName == "Contraband" ? caseData["KnifeCosts"][itemName] : caseData["ItemCosts"][rarityName][itemName])).filter(([key, value]) => !key.includes("StatTrak") && !key.includes('totalItemValue')).map(([key, value]) => (
                                    <TableRow key={key} style={{ width: '128px', textAlign: 'center' }}>
                                        <TableCell key={key} component="th" scope="row" sx={{ textAlign: 'center', fontSize: '0.9rem', lineHeight: '0.8' }} >

                                            {smallWearNames[realWearNames[key]]}
                                        </TableCell>

                                        {Object.entries((rarityName == "Contraband" ? caseData["KnifeCosts"][itemName] : caseData["ItemCosts"][rarityName][itemName])).filter(([key, value]) => key.includes("StatTrak")).length > 0 &&
                                            <TableCell key={key} component="th" scope="row" sx={{
                                                cursor: 'pointer', textAlign: 'center', fontSize: '0.9rem', lineHeight: '0.8',
                                            }}>
                                                <a style={{ color: rarityName == "Contraband" ? (caseData?.KnifeEstimates?.[itemName]?.[key] ?? false) ? "rgb(131 131 131)" : "rgb(207, 106, 50)" : (caseData?.ItemEstimates?.[rarityName]?.[itemName]?.[key] ?? false) ? "rgb(131 131 131)" : "rgb(207, 106, 50)", textDecoration: 'none' }} href={caseData["IsCSFloat"] ? csfloatLink((caseData.SouvenirPackName != null ? "Souvenir " : "") + itemName, realWearNames[key.replace('StatTrak™ ', '')], true, rarityName === 'Contraband') : caseData["ISTradeit"] ? tradeitLink((caseData.SouvenirPackName != null ? "Souvenir " : "") + itemName, realWearNames[key.replace('StatTrak™ ', '')], true, rarityName === 'Contraband') : caseData["IsBuff"] ? buffLink((caseData.SouvenirPackName != null ? "Souvenir " : "") + itemName, realWearNames[key.replace('StatTrak™ ', '')], true, rarityName === 'Contraband') : caseData["IsSkinport"] ? skinportLink((caseData.SouvenirPackName != null ? "Souvenir " : "") + itemName, caseData, true, realWearNames[key.replace('StatTrak™ ', '')], false, rarityName === 'Contraband') : steamLink((caseData.SouvenirPackName != null ? "Souvenir " : "") + itemName, realWearNames[key.replace('StatTrak™ ', '')], true, rarityName === 'Contraband')} target="_blank">

                                                    <div style={{ textDecoration: 'underline', }}>{formatNumber((rarityName == "Contraband" ? caseData["KnifeCosts"][itemName] : caseData["ItemCosts"][rarityName][itemName])["StatTrak\u2122 " + key] / exchangeRate, true)}</div>
                                                    <div style={{ display: 'flex', fontSize: '11px', lineHeight: '0.4', marginTop: 'auto', marginBottom: 'auto', padding: '2px', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', color: (rarityName == "Contraband" ? caseData?.["KnifeROIs"]?.[itemName] : caseData?.["ItemROIs"]?.[rarityName]?.[itemName])?.["StatTrak\u2122 " + key]?.[investType == "1y" ? "ROI" : investType == "6m" ? "6M_ROI" : "1M_ROI"] > -0.01 ? "green" : "rgb(187, 23, 26)" }}>{(rarityName == "Contraband" ? caseData?.["KnifeROIs"]?.[itemName] : caseData?.["ItemROIs"]?.[rarityName]?.[itemName])?.["StatTrak\u2122 " + key]?.[investType == "1y" ? "ROI" : investType == "6m" ? "6M_ROI" : "1M_ROI"] > -0.01 ? <TrendingUpIcon style={{ width: "16px", height: "12px" }} /> : <TrendingDownIcon style={{ width: "16px", height: "12px" }} />}{((rarityName == "Contraband" ? caseData?.["KnifeROIs"]?.[itemName] : caseData?.["ItemROIs"]?.[rarityName]?.[itemName])?.["StatTrak\u2122 " + key]?.[investType == "1y" ? "ROI" : investType == "6m" ? "6M_ROI" : "1M_ROI"] ?? 0) > -0.01 ? "+" : "-"}{formatNumber(Math.abs(Math.round((rarityName == "Contraband" ? caseData?.["KnifeROIs"]?.[itemName] : caseData?.["ItemROIs"]?.[rarityName]?.[itemName])?.["StatTrak\u2122 " + key]?.[investType == "1y" ? "ROI" : investType == "6m" ? "6M_ROI" : "1M_ROI"] ?? 0)), false)}%</div>
                                                </a>
                                            </TableCell>

                                        }
                                        <TableCell key={key} component="th" scope="row" sx={{
                                            cursor: 'pointer', textAlign: 'center', fontSize: '0.9rem', lineHeight: '0.8',
                                        }}>
                                            <a style={{
                                                textDecoration: 'none', color: rarityName == "Contraband" ? (caseData?.KnifeEstimates?.[itemName]?.[key] ?? false) ? "rgb(131 131 131)" : "white" : (caseData?.ItemEstimates?.[rarityName]?.[itemName]?.[key] ?? false) ? "rgb(131 131 131)" : "white"
                                            }} href={caseData["IsCSFloat"] ? csfloatLink((caseData.SouvenirPackName != null ? "Souvenir " : "") + itemName, realWearNames[key.replace('StatTrak™ ', '')], false, rarityName === 'Contraband') : caseData["ISTradeit"] ? tradeitLink((caseData.SouvenirPackName != null ? "Souvenir " : "") + itemName, realWearNames[key.replace('StatTrak™ ', '')], false, rarityName === 'Contraband') : caseData["IsBuff"] ? buffLink((caseData.SouvenirPackName != null ? "Souvenir " : "") + itemName, realWearNames[key.replace('StatTrak™ ', '')], false, rarityName === 'Contraband') : caseData["IsSkinport"] ? skinportLink((caseData.SouvenirPackName != null ? "Souvenir " : "") + itemName, caseData, false, realWearNames[key.replace('StatTrak™ ', '')], false, rarityName === 'Contraband') : steamLink((caseData.SouvenirPackName != null ? "Souvenir " : "") + itemName, realWearNames[key.replace('StatTrak™ ', '')], false, rarityName === 'Contraband')} target="_blank">

                                                <div style={{ textDecoration: 'underline', }}>{formatNumber(value / exchangeRate, true)}</div>
                                                <div style={{ display: 'flex', fontSize: '11px', lineHeight: '0.4', marginTop: 'auto', marginBottom: 'auto', padding: '2px', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', color: (rarityName == "Contraband" ? caseData?.["KnifeROIs"]?.[itemName] : caseData?.["ItemROIs"]?.[rarityName]?.[itemName])?.[key]?.[investType == "1y" ? "ROI" : investType == "6m" ? "6M_ROI" : "1M_ROI"] > -0.01 ? "green" : "rgb(187, 23, 26)" }}>{(rarityName == "Contraband" ? caseData?.["KnifeROIs"]?.[itemName] : caseData?.["ItemROIs"]?.[rarityName]?.[itemName])?.[key]?.[investType == "1y" ? "ROI" : investType == "6m" ? "6M_ROI" : "1M_ROI"] > -0.01 ? <TrendingUpIcon style={{ width: "16px", height: "12px" }} /> : <TrendingDownIcon style={{ width: "16px", height: "12px" }} />}{((rarityName == "Contraband" ? caseData?.["KnifeROIs"]?.[itemName] : caseData?.["ItemROIs"]?.[rarityName]?.[itemName])?.[key]?.[investType == "1y" ? "ROI" : investType == "6m" ? "6M_ROI" : "1M_ROI"] ?? 0) > -0.01 ? "+" : "-"}{formatNumber(Math.abs(Math.round((rarityName == "Contraband" ? caseData?.["KnifeROIs"]?.[itemName] : caseData?.["ItemROIs"]?.[rarityName]?.[itemName])?.[key]?.[investType == "1y" ? "ROI" : investType == "6m" ? "6M_ROI" : "1M_ROI"] ?? 0)), false)}%</div>
                                            </a>
                                        </TableCell>

                                    </TableRow>
                                ))}
                                {/* {Object.entries((rarityName == "Contraband" ? caseData["KnifeCosts"][itemName] : caseData["ItemCosts"][rarityName][itemName])).filter(([key, value]) => key.includes("StatTrak")).length > 0 ?
  
                                  <TableRow>
                                      
                                      {Object.entries((rarityName == "Contraband" ? caseData["KnifeCosts"][itemName] : caseData["ItemCosts"][rarityName][itemName])).filter(([key, value]) => key.includes("StatTrak")).map(([key, value]) => (
                                          <TableCell key={key} component="th" scope="row" sx={{ color: "rgb(207, 106, 50)", cursor: 'pointer', textDecoration: 'underline', textAlign: 'center' }} onClick={() => window.open(caseData["IsBuff"] ? buffLink(caseData.CollectionType == "Souvenir" ? "Souvenir ": "" + itemName, realWearNames[key.replace('StatTrak™ ', '')], true, rarityName === 'Contraband') : caseData["IsSkinport"] ? skinportLink(caseData.CollectionType == "Souvenir" ? "Souvenir ": "" + caseData.CollectionType == "Souvenir" ? "Souvenir ": "" + itemName, caseData, true, realWearNames[key.replace('StatTrak™ ', '')], true, rarityName === 'Contraband') : steamLink(caseData.CollectionType == "Souvenir" ? "Souvenir ": "" + caseData.CollectionType == "Souvenir" ? "Souvenir ": "" + itemName, realWearNames[key.replace('StatTrak™ ', '')], true, rarityName === 'Contraband'), "_blank")}>
                                              {currencySymbol}{formatNumber(value / exchangeRate)}
                                          </TableCell>
                                      ))}
                                  </TableRow> : <div />}
                              <TableRow>
                                  
                                  {Object.entries((rarityName == "Contraband" ? caseData["KnifeCosts"][itemName] : caseData["ItemCosts"][rarityName][itemName])).filter(([key, value]) => !key.includes("StatTrak") && !key.includes('totalItemValue')).map(([key, value]) => (
                                      <TableCell key={key} component="th" scope="row" onClick={() => window.open(caseData["IsBuff"] ? buffLink((caseData.SouvenirPackName != null ? "Souvenir ": "") + itemName, realWearNames[key.replace('StatTrak™ ', '')], false, rarityName === 'Contraband') : caseData["IsSkinport"] ? skinportLink((caseData.SouvenirPackName != null ? "Souvenir ": "") + itemName, caseData,  false,  realWearNames[key.replace('StatTrak™ ', '')], false, rarityName === 'Contraband') : steamLink((caseData.SouvenirPackName != null ? "Souvenir ": "") + itemName, realWearNames[key.replace('StatTrak™ ', '')], false, rarityName === 'Contraband'))} sx={{ cursor: 'pointer', textDecoration: 'underline', textAlign: 'center' }}>
                                          {currencySymbol}{formatNumber(value / exchangeRate)}
                                      </TableCell>
                                  ))}
                              </TableRow> */}
                                {/* <TableRow>
                <TableCell component="th" rowspan="2" scope="row">
                Total Item Value
                </TableCell>
                <TableCell colSpan={Object.entries((rarityName == "Contraband" ? caseData["KnifeCosts"][itemName]: caseData["ItemCosts"][rarityName][itemName])).filter(([key, value]) => !key.includes("StatTrak")).length}>${totalItemValue.toFixed(2)}</TableCell>
            </TableRow> */}
                            </TableBody>
                        </Table>
                    </TableContainer>

                </Paper>
            </Backdrop> :
            <Backdrop
                sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, }}
                open={true}
                onClick={onClose}
            >
                <Paper sx={{ p: 2, position: 'relative' }} onClick={handlePaperClick}>
                    <IconButton
                        sx={{ position: 'absolute', top: 8, right: 8, zIndex: 1 }}
                        onClick={onClose}
                    >
                        <CloseIcon />
                    </IconButton>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        <Typography variant="h6" gutterBottom style={{ margin: 'auto', width: '100%', textAlign: 'center' }}>
                            1 in {formatNumber(1 / item["ItemChance"], false)}
                        </Typography>
                        <Typography variant="h5" gutterBottom style={{ margin: 'auto', width: '100%', textAlign: 'center' }}>
                            {itemName}
                        </Typography>
                        <Typography variant="h6" gutterBottom style={{
                            margin: 'auto', width: '100%', textAlign: 'center', color:
                                (caseData?.ISTradeit ? (rarityName === "Contraband" ?
                                    (typeof caseData?.KnifeEstimates?.[itemName] === "object" ?
                                        (Object.entries(caseData?.KnifeEstimates?.[itemName]).filter(([key, value]) => value === true && key.includes("StatTrak")).length * 0.1 +
                                            Object.entries(caseData?.KnifeEstimates?.[itemName]).filter(([key, value]) => value === true && !key.includes("StatTrak")).length <
                                            Object.entries(caseData?.KnifeEstimates?.[itemName]).filter(([key, value]) => value === false && key.includes("StatTrak")).length * 0.1 +
                                            Object.entries(caseData?.KnifeEstimates?.[itemName]).filter(([key, value]) => value === false && !key.includes("StatTrak")).length) :
                                        (caseData?.KnifeEstimates?.[itemName] === true)
                                    ) ? "white" : "rgb(131 131 131)" :
                                    (typeof caseData?.ItemEstimates?.[rarityName]?.[itemName] === "object" ?
                                        (Object.entries(caseData?.ItemEstimates?.[rarityName]?.[itemName]).filter(([key, value]) => value === true && key.includes("StatTrak")).length * 0.1 +
                                            Object.entries(caseData?.ItemEstimates?.[rarityName]?.[itemName]).filter(([key, value]) => value === true && !key.includes("StatTrak")).length <
                                            Object.entries(caseData?.ItemEstimates?.[rarityName]?.[itemName]).filter(([key, value]) => value === false && key.includes("StatTrak")).length * 0.1 +
                                            Object.entries(caseData?.ItemEstimates?.[rarityName]?.[itemName]).filter(([key, value]) => value === false && !key.includes("StatTrak")).length) :
                                        (caseData?.ItemEstimates?.[rarityName]?.[itemName] === true)
                                    ) ? "white" : "rgb(131 131 131)"
                                ) : "white")
                        }}>
                            {formatNumber((rarityName === "Contraband" ? caseData["KnifeCosts"][itemName]['totalItemValue'] : caseData["ItemCosts"][rarityName][itemName]['totalItemValue']) / exchangeRate, true)} AVG
                        </Typography>
                    </div>
                    {/* <Typography variant="h6" gutterBottom>
      {itemName.description}
    </Typography> */}
                    {/* Add any additional content you want to display in the overlay */}
                    {/* <BarChart width={500} height={500} data={itemNameWears} layout="horizontal">
    <CartesianGrid strokeDasharray="3 3" />
    <XAxis type="number" />
    <YAxis dataKey="name" type="category" />
    <Tooltip />
    <Bar dataKey="value" fill="#8884d8" label={CustomizedLabel}>
        {Object.entries(itemNameWears).map((entry, index) => (
        <Cell key={`cell-${index}`} fill={"red"} />
        ))}
    </Bar>
    </BarChart> */}
                    <div style={{ display: 'flex', flexDirection: 'horizontal', }}>
                        <img src={caseData["Items"][itemName]["ImageUrl"]} style={{ height: '250px' }} />
                        <BarChart style={{ marginLeft: 'auto' }} width={128 * data.length} height={250} data={data} layout="horizontal" barCategoryGap={1} margin={{ top: 50, right: 0, left: 0, bottom: 0 }}>
                            {/* <CartesianGrid strokeDasharray="3 3" /> */}
                            <YAxis type="number" hide />
                            <XAxis dataKey="name" type="category" width={150} hide />
                            {/* <Tooltip /> */}
                            <Bar dataKey="value" fill="#8884d8" label={CustomizedLabel}>
                                {/* {data.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={"red"} />
        ))} */}
                            </Bar>
                        </BarChart>
                    </div>
                    <TableContainer component={Paper} >
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell></TableCell>
                                    {Object.entries((rarityName == "Contraband" ? caseData["KnifeCosts"][itemName] : caseData["ItemCosts"][rarityName][itemName])).filter(([key, value]) => !key.includes("StatTrak") && !key.includes('totalItemValue')).map(([key, value]) => (
                                        <TableCell key={key} style={{ width: '128px', textAlign: 'center' }}>{realWearNames[key]}</TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {Object.entries((rarityName == "Contraband" ? caseData["KnifeCosts"][itemName] : caseData["ItemCosts"][rarityName][itemName])).filter(([key, value]) => key.includes("StatTrak")).length > 0 ?

                                    <TableRow>
                                        <TableCell component="th" scope="row" sx={{ color: "rgb(207, 106, 50)" }}>
                                            ST (10x Rarer)
                                        </TableCell>
                                        {Object.entries((rarityName == "Contraband" ? caseData["KnifeCosts"][itemName] : caseData["ItemCosts"][rarityName][itemName])).filter(([key, value]) => key.includes("StatTrak")).map(([key, value]) => (
                                            <TableCell key={key} component="th" scope="row" sx={{
                                                color: rarityName == "Contraband" ? (caseData?.KnifeEstimates?.[itemName]?.[key] ?? false) ? "rgb(131 131 131)" : "rgb(207, 106, 50)" : (caseData?.ItemEstimates?.[rarityName]?.[itemName]?.[key] ?? false) ? "rgb(131 131 131)" : "rgb(207, 106, 50)",
                                                cursor: 'pointer', textAlign: 'center',
                                            }}>
                                                <a style={{ color: rarityName == "Contraband" ? (caseData?.KnifeEstimates?.[itemName]?.[key] ?? false) ? "rgb(131 131 131)" : "rgb(207, 106, 50)" : (caseData?.ItemEstimates?.[rarityName]?.[itemName]?.[key] ?? false) ? "rgb(131 131 131)" : "rgb(207, 106, 50)", textDecoration: 'none', }} href={caseData["IsCSFloat"] ? csfloatLink(caseData.CollectionType == "Souvenir" ? "Souvenir " : "" + itemName, realWearNames[key.replace('StatTrak™ ', '')], true, rarityName === 'Contraband') : caseData["ISTradeit"] ? tradeitLink(caseData.CollectionType == "Souvenir" ? "Souvenir " : "" + itemName, realWearNames[key.replace('StatTrak™ ', '')], true, rarityName === 'Contraband') : caseData["IsBuff"] ? buffLink(caseData.CollectionType == "Souvenir" ? "Souvenir " : "" + itemName, realWearNames[key.replace('StatTrak™ ', '')], true, rarityName === 'Contraband') : caseData["IsSkinport"] ? skinportLink(caseData.CollectionType == "Souvenir" ? "Souvenir " : "" + caseData.CollectionType == "Souvenir" ? "Souvenir " : "" + itemName, caseData, true, realWearNames[key.replace('StatTrak™ ', '')], true, rarityName === 'Contraband') : steamLink(caseData.CollectionType == "Souvenir" ? "Souvenir " : "" + caseData.CollectionType == "Souvenir" ? "Souvenir " : "" + itemName, realWearNames[key.replace('StatTrak™ ', '')], true, rarityName === 'Contraband')} target="_blank">

                                                    <div style={{ textDecoration: 'underline', }}>{formatNumber(value / exchangeRate, true)}</div>
                                                    <div style={{ display: 'flex', fontSize: '12px', lineHeight: '0.4', marginTop: '-3px', marginBottom: 'auto', padding: '2px', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', color: ((rarityName == "Contraband" ? caseData?.["KnifeROIs"]?.[itemName] : caseData?.["ItemROIs"]?.[rarityName]?.[itemName])?.[key]?.[investType == "1y" ? "ROI" : investType == "6m" ? "6M_ROI" : "1M_ROI"] ?? 0) > -0.01 ? "green" : "rgb(187, 23, 26)" }}>{((rarityName == "Contraband" ? caseData?.["KnifeROIs"]?.[itemName] : caseData?.["ItemROIs"]?.[rarityName]?.[itemName])?.[key]?.[investType == "1y" ? "ROI" : investType == "6m" ? "6M_ROI" : "1M_ROI"] ?? 0) > -0.01 ? <TrendingUpIcon style={{ width: "16px", height: "12px" }} /> : <TrendingDownIcon style={{ width: "16px", height: "12px" }} />}{((rarityName == "Contraband" ? caseData?.["KnifeROIs"]?.[itemName] : caseData?.["ItemROIs"]?.[rarityName]?.[itemName])?.[key]?.[investType == "1y" ? "ROI" : investType == "6m" ? "6M_ROI" : "1M_ROI"] ?? 0) > -0.01 ? "+" : "-"}{formatNumber(Math.abs(Math.round((rarityName == "Contraband" ? caseData?.["KnifeROIs"]?.[itemName] : caseData?.["ItemROIs"]?.[rarityName]?.[itemName])?.[key]?.[investType == "1y" ? "ROI" : investType == "6m" ? "6M_ROI" : "1M_ROI"] ?? 0)), false)}%</div>
                                                </a>
                                            </TableCell>

                                        ))}
                                    </TableRow> : <div />}
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        Normal
                                    </TableCell>
                                    {Object.entries((rarityName == "Contraband" ? caseData["KnifeCosts"][itemName] : caseData["ItemCosts"][rarityName][itemName])).filter(([key, value]) => !key.includes("StatTrak") && !key.includes('totalItemValue')).map(([key, value]) => (
                                        <TableCell key={key} component="th" scope="row" sx={{
                                            cursor: 'pointer', textAlign: 'center',
                                        }}>
                                            <a style={{ color: rarityName == "Contraband" ? (caseData?.KnifeEstimates?.[itemName]?.[key] ?? false) ? "rgb(131 131 131)" : "white" : (caseData?.ItemEstimates?.[rarityName]?.[itemName]?.[key] ?? false) ? "rgb(131 131 131)" : "white", textDecoration: 'none', }} href={caseData["IsCSFloat"] ? csfloatLink((caseData.SouvenirPackName != null ? "Souvenir " : "") + itemName, realWearNames[key.replace('StatTrak™ ', '')], false, rarityName === 'Contraband') : caseData["ISTradeit"] ? tradeitLink((caseData.SouvenirPackName != null ? "Souvenir " : "") + itemName, realWearNames[key.replace('StatTrak™ ', '')], false, rarityName === 'Contraband') : caseData["IsBuff"] ? buffLink((caseData.SouvenirPackName != null ? "Souvenir " : "") + itemName, realWearNames[key.replace('StatTrak™ ', '')], false, rarityName === 'Contraband') : caseData["IsSkinport"] ? skinportLink((caseData.SouvenirPackName != null ? "Souvenir " : "") + itemName, caseData, false, realWearNames[key.replace('StatTrak™ ', '')], false, rarityName === 'Contraband') : steamLink((caseData.SouvenirPackName != null ? "Souvenir " : "") + itemName, realWearNames[key.replace('StatTrak™ ', '')], false, rarityName === 'Contraband')} target="_blank">
                                                <div style={{ textDecoration: 'underline', }}>{formatNumber(value / exchangeRate, true)}</div>
                                                <div style={{ display: 'flex', fontSize: '12px', lineHeight: '0.4', marginTop: '-3px', marginBottom: 'auto', padding: '2px', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', color: ((rarityName == "Contraband" ? caseData?.["KnifeROIs"]?.[itemName] : caseData?.["ItemROIs"]?.[rarityName]?.[itemName])?.[key]?.[investType == "1y" ? "ROI" : investType == "6m" ? "6M_ROI" : "1M_ROI"] ?? 0) > -0.01 ? "green" : "rgb(187, 23, 26)" }}>{((rarityName == "Contraband" ? caseData?.["KnifeROIs"]?.[itemName] : caseData["ItemROIs"]?.[rarityName]?.[itemName])?.[key]?.[investType == "1y" ? "ROI" : investType == "6m" ? "6M_ROI" : "1M_ROI"] ?? 0) > -0.01 ? <TrendingUpIcon style={{ width: "16px", height: "12px" }} /> : <TrendingDownIcon style={{ width: "16px", height: "12px" }} />}{((rarityName == "Contraband" ? caseData?.["KnifeROIs"]?.[itemName] : caseData?.["ItemROIs"]?.[rarityName]?.[itemName])?.[key]?.[investType == "1y" ? "ROI" : investType == "6m" ? "6M_ROI" : "1M_ROI"] ?? 0) > -0.01 ? "+" : "-"}{formatNumber(Math.abs(Math.round((rarityName == "Contraband" ? caseData?.["KnifeROIs"]?.[itemName] : caseData?.["ItemROIs"]?.[rarityName]?.[itemName])?.[key]?.[investType == "1y" ? "ROI" : investType == "6m" ? "6M_ROI" : "1M_ROI"] ?? 0)), false)}%</div>
                                            </a>
                                        </TableCell>
                                    ))}
                                </TableRow>
                                {/* <TableRow>
            <TableCell component="th" rowspan="2" scope="row">
            Total Item Value
            </TableCell>
            <TableCell colSpan={Object.entries((rarityName == "Contraband" ? caseData["KnifeCosts"][itemName]: caseData["ItemCosts"][rarityName][itemName])).filter(([key, value]) => !key.includes("StatTrak")).length}>${totalItemValue.toFixed(2)}</TableCell>
        </TableRow> */}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <div style={{ maxWidth: '400px', margin: 'auto' }}>
                        <MemoizedCaseGridItem
                            key={baseData.id}
                            item={baseData}
                            // index={0}
                            pricingSource={pricingSource}
                            currency={currency}
                            exchangeRate={exchangeRate}
                            sortBy={sortBy}
                            isMobileLayout={isMobileLayout}
                            showUnbox={showUnbox}
                            showProfit={showProfit}
                            showInvest={showInvest}
                            numListings={baseData.NumListings}
                            showListings={showListings}
                            investType={investType}
                            isCaseEstimate={pricingSource == "Tradeit" ? baseData?.TradeitEstimateCase : false}
                        />
                    </div>

                </Paper>
            </Backdrop>
    );
}

export default Overlay;