import * as React from 'react';
import CookieConsent from "react-cookie-consent";
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import AutoSizer from 'react-virtualized-auto-sizer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import { skinportLink, buffLink, steamLink, csfloatLink, tradeitLink, skinsearchLink } from './allLinks.js';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { TableHead, TableBody, } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import Container from '@mui/material/Container';
// import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Drawer from '@mui/material/Drawer';
import NotificationsIcon from '@mui/icons-material/Notifications';
// import { mainListItems, secondaryListItems } from './listItems';
import useMediaQuery from '@mui/material/useMediaQuery';
import { VariableSizeGrid as Grid } from 'react-window';
// import Chart from './Chart';
// import Deposits from './Deposits';
// import Orders from './Orders';
// import Case from './Case';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
// import FormControl from '@mui/material/FormControl';
// import CaseItems from './CaseItems';
import { Card, CardMedia, Chip, FormControl, Icon } from '@mui/material';
import { useState, useEffect } from 'react';
import { ToggleButtonGroup, ToggleButton, FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import OpenBox from './OpenBox.svg';
import './Rankings.css'
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import SettingsIcon from '@mui/icons-material/Settings';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import getSymbolFromCurrency from 'currency-symbol-map'
import CloseIcon from '@mui/icons-material/Close';
import Tooltip from '@mui/material/Tooltip';
import { useMemo } from 'react';
import Scrollbars from 'react-custom-scrollbars-2';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import { CaseGridItem } from './CaseGridItem';
import SortIcon from '@mui/icons-material/Sort';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
// import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
// import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import ReactGA from 'react-ga4';
import { FixedSizeGrid } from 'react-window';
import { AttachMoney, LocalShipping, PriceCheck } from '@mui/icons-material';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, ResponsiveContainer, Cell, Legend } from 'recharts';
import { Backdrop, Radio, Table, TableRow, TableCell, TableContainer, OutlinedInput } from "@mui/material";
import { min } from 'lodash';
import Overlay from './Overlay.js';


const redRarityNums = { 'Contraband': 223, 'Covert': 187, 'Classified': 158, 'Restricted': 104, 'Mil_Spec': 2, 'Industrial': 94, 'Consumer': 176 };
const greenRarityNums = { 'Contraband': 199, 'Covert': 23, 'Classified': 0, 'Restricted': 50, 'Mil_Spec': 79, 'Industrial': 152, 'Consumer': 195 };
const blueRarityNums = { 'Contraband': 0, 'Covert': 26, 'Classified': 197, 'Restricted': 234, 'Mil_Spec': 228, 'Industrial': 217, 'Consumer': 217 };
const realWearNames = { 'FactoryNew': 'Factory New', 'MinimalWear': 'Minimal Wear', 'FieldTested': 'Field-Tested', 'WellWorn': 'Well-Worn', 'BattleScarred': 'Battle-Scarred' };
const smallSizedCodeWearNames = { 'FactoryNew': 'FN', 'MinimalWear': 'MW', 'FieldTested': 'FT', 'WellWorn': 'WW', 'BattleScarred': 'BS', 'StatTrak™ FactoryNew': 'ST FN', 'StatTrak™ MinimalWear': 'ST MW', 'StatTrak™ FieldTested': 'ST FT', 'StatTrak™ WellWorn': 'ST WW', 'StatTrak™ BattleScarred': 'ST BS' }




const CarePackageBox = ({ carePackageData, formatNumber, exchangeRate, delay }) => {
  const [visibleData, setVisibleData] = useState([]);
  const [animationKey, setAnimationKey] = useState(0);
  const [fadeOut, setFadeOut] = useState(false);

  // When carePackageData changes, trigger fade-out first
  useEffect(() => {
    if (!carePackageData?.length) return;
  
    if (parseFloat(delay) <= 0.5) {
      // Skip animation
      setVisibleData(carePackageData); // Replace data immediately
      // setAnimationKey(prev => prev + 1); // Trigger drop-in
      return;
    }
  
    setFadeOut(true); // Start fading out
    const timeout = setTimeout(() => {
      setVisibleData(carePackageData); // Replace data
      setAnimationKey(prev => prev + 1); // Trigger drop-in
      setFadeOut(false); // Fade-in phase
    }, 100); // Match this with .fade-out animation duration
  
    return () => clearTimeout(timeout);
  }, [carePackageData]);

  if (!visibleData?.length) return null;

  return (
    <div
      style={{
        position: "relative",
        width: "60%",
        paddingTop: "17%",
        borderRadius: "8px",
        overflow: "hidden",
        margin: "auto",
      }}
    >
      <img
        src="http://localhost:8080/assets/weekly_rewards_png.png"
        alt="Care Package Background"
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          objectFit: "cover",
        }}
      />
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          color: "white",
          padding: "20px",
        }}
      >
        <div
          key={animationKey}
          className={fadeOut ? "fade-out" : ""}
          style={{ display: "flex", gap: "16px" }}
        >
          {visibleData.map((item, index) => (
            <div
              key={index}
              className="drop-in"
              style={{
                animationDelay: `${index * 100}ms`,
                border: item?.best
                  ? "2px solid rgba(0, 255, 0, 1)"
                  : "0px solid rgba(255, 255, 255, 1)",
                padding: "4px",
                borderRadius: "8px",
                width: "10vw",
                textAlign: "center",
                position: "relative",
                background:
                  item.rarity != null && item.best
                    ? `linear-gradient(145deg, RGB(${otherRarityNames.hasOwnProperty(item.rarity)
                      ? redRarityNums[otherRarityNames[item.rarity]]
                      : redRarityNums[item.rarity]
                    }, ${otherRarityNames.hasOwnProperty(item.rarity)
                      ? greenRarityNums[otherRarityNames[item.rarity]]
                      : greenRarityNums[item.rarity]
                    }, ${otherRarityNames.hasOwnProperty(item.rarity)
                      ? blueRarityNums[otherRarityNames[item.rarity]]
                      : blueRarityNums[item.rarity]
                    }) 0%, black 100%)`
                    : "rgba(50, 50, 50, 0.8)",
              }}
            >
              <img
                src={item.image}
                alt={item.item}
                style={{ width: "80%", height: "60%", objectFit: "contain" }}
              />
              {item?.chance != null && (
                <p
                  style={{
                    position: "absolute",
                    zIndex: 2,
                    top: "-4%",
                    left: "50%",
                    transform: "translateX(-50%)",
                    fontSize: "14px",
                  }}
                >
                  1 in {formatNumber(1 / item?.chance, false)}
                </p>
              )}
              <p
                style={{
                  position: "absolute",
                  zIndex: 2,
                  top: "44.2%",
                  left: "50%",
                  transform: "translateX(-50%)",
                  fontSize: "14px",
                }}
              >
                {item?.float?.toFixed(3)}
              </p>
              <p
                style={{
                  fontSize: "16px",
                  lineHeight: "1.2em",
                  minHeight: "2.4em",
                  marginTop: "-4px",
                }}
              >
                {item.item.split("(")[0]}
                {item.wear != null && <br />}
                {item.wear != null &&
                  "(" + item.wear + ")"}
              </p>
              <p
                style={{
                  fontSize: "18px",
                  color: "lightgray",
                  fontWeight: "bold",
                }}
              >
                {item.price
                  ? formatNumber(item.price / exchangeRate, true)
                  : formatNumber(item.price / exchangeRate, true)}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const otherRarityNames = { 'Extraordinary': 'Covert', 'Exotic': 'Classified', 'Remarkable': 'Restricted', 'High_Grade': 'Mil_Spec', 'Superior': 'Classified', 'Master': 'Covert', 'Exceptional': 'Restricted', 'Distinguished': 'Mil_Spec' }




// ReactGA.pageview(window.location.pathname + window.location.search);


const drawerWidth = 240;


const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

const StyledToggleButton = styled(ToggleButton)({
  "&.Mui-selected, &.Mui-selected:hover": {
    color: "white",
    backgroundColor: '#bb171a',
    marginLeft: '-1px',
  }
});

const rarity_dict = {
  0: "Consumer",
  1: "Industrial",
  2: "Mil_Spec",
  5: "Restricted",
  8: "Classified",
  11: "Covert"
}

const wearNames = [
  'Factory New',
  'Minimal Wear',
  'Field-Tested',
  'Well-Worn',
  'Battle-Scarred',
];

const wearRanges = {
  'Factory New': 0.07,
  'Minimal Wear': 0.15,
  'Field-Tested': 0.38,
  'Well-Worn': 0.45,
  'Battle-Scarred': 1,
};

const smallWearNames = {
  'Factory New': "FN",
  'Minimal Wear': "MW",
  'Field-Tested': "FT",
  'Well-Worn': "WW",
  'Battle-Scarred': "BS",
};

const wearRangeStarts = {
  'Factory New': 0,
  'Minimal Wear': 0.07,
  'Field-Tested': 0.15,
  'Well-Worn': 0.38,
  'Battle-Scarred': 0.45,
  'magic': 1, // not used, but needed for calculation
};

const wearDropRates = {
  'Factory New': 0.03,
  'Minimal Wear': 0.24,
  'Field-Tested': 0.33,
  'Well-Worn': 0.24,
  'Battle-Scarred': 0.16,
};

const wearRangeStartDrops = {
  'Factory New': 0,
  'Minimal Wear': 0.08,
  'Field-Tested': 0.16,
  'Well-Worn': 0.39,
  'Battle-Scarred': 0.46,
};

const wearCodeNames = {
  'Factory New': "FactoryNew",
  'Minimal Wear': "MinimalWear",
  'Field-Tested': "FieldTested",
  'Well-Worn': "WellWorn",
  'Battle-Scarred': "BattleScarred",
}

const randomImages = {
  "Charm Detachment Pack": "http://localhost:8080/assets/keychain_remove_tool_pack_png.png",
  "Random Graffiti0": "https://community.cloudflare.steamstatic.com/economy/image/IzMF03bi9WpSBq-S-ekoE33L-iLqGFHVaU25ZzQNQcXdB2ozio1RrlIWFK3UfvMYB8UsvjiMXojflsZalyxSh31CIyHz2GZ-KuFpPsrTzBG0pPuIHnX7ZHnAfCSPTg5tSeELZzrRqjCi5OXAE2vNQe5_QVpQfqNQpGYcb86Ba0A1ysdVrCO7mEhyDBs9ftFPYAOilyIWZbt8zpsoC_UC/360fx360f",
  "Random Graffiti1": "https://community.cloudflare.steamstatic.com/economy/image/IzMF03bi9WpSBq-S-ekoE33L-iLqGFHVaU25ZzQNQcXdB2ozio1RrlIWFK3UfvMYB8UsvjiMXojflsZalyxSh31CIyHz2GZ-KuFpPsrTzBG0pvaKUnDzOTLGenWITAYwS7FZN2Dd9zWj4ryXS23JEu1_F1sDePAD92BLac2Xf0xqwtRUp2qqmlFxCh84ftwbcVm4nnVGFuh0GdE/360fx360f",
  "Random Graffiti2": "https://community.cloudflare.steamstatic.com/economy/image/IzMF03bi9WpSBq-S-ekoE33L-iLqGFHVaU25ZzQNQcXdB2ozio1RrlIWFK3UfvMYB8UsvjiMXojflsZalyxSh31CIyHz2GZ-KuFpPsrTzBG0pe2BEHXlJmDHdnWAGQ88ReUMYTnZ92at5OiSF2zKE-ApEgpVL_QE9DBOOZuJOEAjlNlc7Wa3m0tvEwMkZsxWfBbmyyIQZbxwGnjry04/360fx360f",
  "Random Graffiti3": "https://community.cloudflare.steamstatic.com/economy/image/IzMF03bi9WpSBq-S-ekoE33L-iLqGFHVaU25ZzQNQcXdB2ozio1RrlIWFK3UfvMYB8UsvjiMXojflsZalyxSh31CIyHz2GZ-KuFpPsrTzBG0q_eADEvkYDjQYXHdHg9sS7VaNT6I9mCnsbyWF27BErsuEA5QL6oD9DVMO5yLOBBugYYVu2u_0UdyEhk6f9BKZAarxm1ONekmkXQQMuHZuwA/360fx360f",
  "Random Graffiti4": "https://community.cloudflare.steamstatic.com/economy/image/IzMF03bi9WpSBq-S-ekoE33L-iLqGFHVaU25ZzQNQcXdB2ozio1RrlIWFK3UfvMYB8UsvjiMXojflsZalyxSh31CIyHz2GZ-KuFpPsrTzBG0r-eOF3j2azL7Ky7VEF96U-dfPT6PqzH05byVSjzJEu99Fw4EfPYG8jBIPZiJaxo5hYZf8jfpzh0zDhgvNMZJfACpx2EfJbQ1xDhPIJ8BynD1fa7YrHA/360fx360f",
  "Random Graffiti5": "https://community.cloudflare.steamstatic.com/economy/image/IzMF03bi9WpSBq-S-ekoE33L-iLqGFHVaU25ZzQNQcXdB2ozio1RrlIWFK3UfvMYB8UsvjiMXojflsZalyxSh31CIyHz2GZ-KuFpPsrTzBG0pPSEEEvycTKKdnKIHV0wHOdYYz3b-Tuls-3AFDudFOEqQQsGKfQFoWZKbMuLahE615lLpWL-lEtxEQQlZ8lSeR-30ylLN-ohnSVF7bDv6Q/360fx360f",
  "Random Graffiti6": "https://community.cloudflare.steamstatic.com/economy/image/IzMF03bi9WpSBq-S-ekoE33L-iLqGFHVaU25ZzQNQcXdB2ozio1RrlIWFK3UfvMYB8UsvjiMXojflsZalyxSh31CIyHz2GZ-KuFpPsrTzBG0quyECnHkVzTWIDLKGVomHLoKMW7d_DKit-nAR2zORrkqSwEFKPQE-jdOPJyMbBI83IcD82HulgptEBFuccpKfx2233gHOK0p0XxFfZIGnCdsWV3ADQ/360fx360f",
  "Random Graffiti7": "https://community.cloudflare.steamstatic.com/economy/image/IzMF03bi9WpSBq-S-ekoE33L-iLqGFHVaU25ZzQNQcXdB2ozio1RrlIWFK3UfvMYB8UsvjiMXojflsZalyxSh31CIyHz2GZ-KuFpPsrTzBG0oPqID0v0ejjXPCTdUgprGbMPN2ve_GWg5LuRRDrOFOt_RApXf_NVoGFANciLakNr0YMKqDz2h0p6WBUnfspUfRq33n0DPaR4ynlFdJ9aZu7u-pY/360fx360f",
  "Random Graffiti8": "https://community.cloudflare.steamstatic.com/economy/image/IzMF03bi9WpSBq-S-ekoE33L-iLqGFHVaU25ZzQNQcXdB2ozio1RrlIWFK3UfvMYB8UsvjiMXojflsZalyxSh31CIyHz2GZ-KuFpPsrTzBG0puWyTCa5ODKcLnfaGAoxHLYMNG-LrTCiseXBSjDIQuopRl1XePFQ9TVMb5yLOAx9itAdqGq0mFZwCxo8e9VKaVLvzyMVMOSahMF4dw/360fx360f",
  "Random Graffiti9": "https://community.cloudflare.steamstatic.com/economy/image/IzMF03bi9WpSBq-S-ekoE33L-iLqGFHVaU25ZzQNQcXdB2ozio1RrlIWFK3UfvMYB8UsvjiMXojflsZalyxSh31CIyHz2GZ-KuFpPsrTzBG0qeODGEv1fSPQKjPfEEcmH7YINjvZqzfwseTHFDmYQe4vQFgEdacCoTZPP5uLOxtp0tRe_DHslAptEBFuccpKfx2233gHOK0p0XxEJ8pXyyaq995QEA/360fx360f",
  "Random Graffiti10": "https://community.cloudflare.steamstatic.com/economy/image/IzMF03bi9WpSBq-S-ekoE33L-iLqGFHVaU25ZzQNQcXdB2ozio1RrlIWFK3UfvMYB8UsvjiMXojflsZalyxSh31CIyHz2GZ-KuFpPsrTzBG0qe6yEHX6ajPFYSSBRQ8xS7ELZjzeqjLz5b7CSmqfF70sFQ0ALvED9GRINM-LPxI41ocVu2u_0UdyEhk6f9BKZAarxm1OMuwmmSJDy46xGGQ/360fx360f",
};


const CACHE_DURATION = 12 * 60 * 60 * 1000; // Cache duration in milliseconds (12 hours)



function CarePackage() {
  //   const data = await fetchData();
  const [open, setOpen] = React.useState(true);
  const cachedTypes = JSON.parse(localStorage.getItem('cachedTypes'));
  const [types, setTypes] = useState(cachedTypes || [
    "Case"
  ]);
  const [currencies, setCurrencies] = useState([]);
  const [openSettings, setOpenSettings] = useState(true);
  const storedTradeitHideUnavailable = (localStorage.getItem('tradeitHide') ?? "true") == "true";
  const [tradeitHideUnavailable, setTradeitHideUnavailable] = useState(storedTradeitHideUnavailable ?? true);
  const storedSortDirection = (localStorage.getItem('sortDirection') ?? "true") == "true";
  const [sortDirection, setSortDirection] = useState(storedSortDirection ?? true);
  const storedShowUnbox = (localStorage.getItem('showUnbox') ?? "true") == "true";
  const [showUnbox, setShowUnbox] = useState(storedShowUnbox ?? true);
  const storedShowProfit = (localStorage.getItem('showProfit') ?? "true") == "true";
  const [showProfit, setShowProfit] = useState(storedShowProfit ?? true);
  const storedShowInvest = (localStorage.getItem('showInvest') ?? "true") == "true";
  const [showInvest, setShowInvest] = useState(storedShowInvest ?? true);
  const storedShowListings = (localStorage.getItem('showListings') ?? "true") == "true";
  const [showListings, setShowListings] = useState(storedShowListings ?? true);
  var storedSort = localStorage.getItem('sortBy');
  storedSort = storedSort == "invest6m" ? "invest" : storedSort == "invest1m" ? "invest" : storedSort;
  const [sortBy, setSortBy] = useState("cost");
  const storedCurrency = localStorage.getItem('currency')
  // || new Intl.NumberFormat(navigator.language, {style: 'currency'}).resolvedOptions().currency;
  const [currency, setCurrency] = useState(storedCurrency || 'usd');
  const cachedUSDExchangeRate = localStorage.getItem('usdExchangeRate');
  const [exchangeRate, setExchangeRate] = useState(cachedUSDExchangeRate || 1);


  const cachedPricingSource = localStorage.getItem('pricingSource');
  const [pricingSource, setPricingSource] = useState(cachedPricingSource || 'Steam');
  const [currencySymbol, setCurrencySymbol] = useState('');
  const cachedSearch = localStorage.getItem('search');
  const [inputValue, setInputValue] = useState(cachedSearch || '');
  const [selectedValue, setSelectedValue] = useState(null);
  const [initialItems, setInitialItems] = useState([
  ]);
  const [collectionData, setCollectionData] = useState({});
  const [expectedReturn, setExpectedReturn] = useState(0);
  const [dropItems, setDropItems] = useState({});
  const [activeDropValue, setActiveDropValue] = useState(null);
  const [commonCases, setCommonCases] = useState({});
  const [rareCases, setRareCases] = useState({});
  // const cachedAllCases = localStorage.getItem('allCases');
  // const [allCases, setAllCases] = useState(cachedAllCases || {});
  // const cachedAllLowCosts = localStorage.getItem('allLowCosts');
  // const [allLowCosts, setAllLowCosts] = useState(cachedAllLowCosts || {});
  const storedInvestType = localStorage.getItem('investType');
  const [investType, setInvestType] = useState(storedInvestType || "1y");
  // const theme = useTheme();
  const [openAdvanced, setOpenAdvanced] = useState(false);
  const [carePackageData, setCarePackageData] = useState(null);
  const [numRepeats, setNumRepeats] = useState(100);
  const [delay, setDelay] = useState(2);
  const [worth, setWorth] = useState(2);
  const [wearsFilter, setWearsFilter] = useState('');
  const [wearsFilterChances, setWearsFilterChances] = useState('');
  const [itemsFilter, setItemsFilter] = useState('');
  const [raritiesFilter, setRaritiesFilter] = useState('');
  const [collectionFilter, setCollectionFilter] = useState('');
  const [filterType, setFilterType] = useState('None');
  const [numCaseSimulates, setNumCaseSimulates] = useState(1);


  function formatNumber(number, isCurrency) {
    // Use the user's locale
    const userLocale = navigator.language || 'en-US';

    // Create Intl.NumberFormat with user's locale
    if (isCurrency) {
      const formatter = new Intl.NumberFormat(userLocale, {
        //   minimumFractionDigits: 1,
        style: "currency",
        currency: currency,
        maximumFractionDigits: Math.abs(number) > 1000 ? 0 : number > 100 ? 1 : 2,
      });
      return formatter.format(number);
    } else {
      const formatter = new Intl.NumberFormat(userLocale, {
        //   minimumFractionDigits: 1,
        maximumFractionDigits: Math.abs(number) > 1000 ? 0 : number > 100 ? 1 : 2,
      });
      return formatter.format(number);
    }

    // Format the number

  }

  const setCollection = (event) => {
    setCollectionFilter(
      event.target.value
    )
    setRaritiesFilter(
      ''
    );
    setItemsFilter(
      ''
    );
    setWearsFilter(
      ''
    );
    setWearsFilterChances(
      ''
    );
  };

  const setRarities = (event) => {
    setRaritiesFilter(
      // On autofill we get a stringified value.
      event.target.value
    );
    setItemsFilter(
      ''
    );
    setWearsFilter(
      ''
    );
    setWearsFilterChances(
      ''
    );
  };

  const setItems = (event) => {
    const selectedItem = dropItems.find(item => item.Name === event.target.value);

    if (selectedItem) {
      setItemsFilter(event.target.value); // Set the selected item filter
      setWearsFilter(''); // Reset the wears filter

      // Calculate wear chances using the selected item's FloatMin and FloatMax
      const testWears = getWears(selectedItem.FloatMin / 100, selectedItem.FloatMax / 100);
      setWearsFilterChances(testWears); // Update the wear chances
    }
  };



  const handleDrawerOpen = () => {
    setOpenAdvanced(true);
  };

  const toggleAdvanced = () => {
    setOpenAdvanced(!openAdvanced);
  };

  const items = useMemo(() => {
    // const searchTerms = inputValue.toLowerCase().split(' ').filter(term => term); // Split input into search terms

    const filteredItems = initialItems.filter(
      (x) =>
      // types.includes(x.CollectionType) &&
      // searchTerms.every(term => x.Name.toLowerCase().includes(term)) && // Ensure all search terms are in the name    
      (x.DropType == "Active Drop" ||
        x.DropType == "Rare Drop")
      // ((x.TradeitEstimateCase !== true || tradeitHideUnavailable) || pricingSource !== "Tradeit")
    );

    const sortKeyMap = {
      unbox: pricingSource + 'ROI',
      cost: 'CollectionPrice' + pricingSource,
      profit: 'Profit' + pricingSource,
      invest: pricingSource + 'iROI',
      invest1M: pricingSource + '1MiROI',
      invest6M: pricingSource + '6MiROI',
      dropType: 'DropType',
      numListings: 'NumListings'
    };

    const sortKey = sortKeyMap[sortBy == "invest" ? investType == "1y" ? sortBy : investType == "6m" ? "invest6M" : "invest1M" : sortBy];
    const sortedItems = [...filteredItems].sort((a, b) => {
      let aValue = a[sortKey];
      let bValue = b[sortKey];

      if (sortBy === "unbox") {
        aValue *= a.CollectionType === "Collection" ? 0.001 : 1;
        bValue *= b.CollectionType === "Collection" ? 0.001 : 1;
      }

      if (sortBy === "dropType") {
        return aValue.localeCompare(bValue);
      }

      return bValue - aValue;
    });

    if (!sortDirection) {
      sortedItems.reverse();
    }

    return sortedItems;
  }, [initialItems]);

  // const commonCases = useMemo(() => {
  //   // const searchTerms = inputValue.toLowerCase().split(' ').filter(term => term); // Split input into search terms

  //   const filteredItems = initialItems.filter(
  //     (x) =>
  //       // types.includes(x.CollectionType) &&
  //       // searchTerms.every(term => x.Name.toLowerCase().includes(term)) && // Ensure all search terms are in the name    
  //       (x.DropType == "Active Drop" &&
  //         x.CollectionType == "Case")
  //     // ((x.TradeitEstimateCase !== true || tradeitHideUnavailable) || pricingSource !== "Tradeit")
  //   );

  //   const sortKeyMap = {
  //     unbox: pricingSource + 'ROI',
  //     cost: 'CollectionPrice' + pricingSource,
  //     profit: 'Profit' + pricingSource,
  //     invest: pricingSource + 'iROI',
  //     invest1M: pricingSource + '1MiROI',
  //     invest6M: pricingSource + '6MiROI',
  //     dropType: 'DropType',
  //     numListings: 'NumListings'
  //   };

  //   const sortKey = sortKeyMap[sortBy == "invest" ? investType == "1y" ? sortBy : investType == "6m" ? "invest6M" : "invest1M" : sortBy];
  //   const sortedItems = [...filteredItems].sort((a, b) => {
  //     let aValue = a[sortKey];
  //     let bValue = b[sortKey];

  //     if (sortBy === "unbox") {
  //       aValue *= a.CollectionType === "Collection" ? 0.001 : 1;
  //       bValue *= b.CollectionType === "Collection" ? 0.001 : 1;
  //     }

  //     if (sortBy === "dropType") {
  //       return aValue.localeCompare(bValue);
  //     }

  //     return bValue - aValue;
  //   });

  //   if (!sortDirection) {
  //     sortedItems.reverse();
  //   }

  //   return sortedItems;
  // }, [initialItems, sortBy, sortDirection]);

  // const rareCases = useMemo(() => {
  //   // const searchTerms = inputValue.toLowerCase().split(' ').filter(term => term); // Split input into search terms

  //   const filteredItems = initialItems.filter(
  //     (x) =>
  //       // types.includes(x.CollectionType) &&
  //       // searchTerms.every(term => x.Name.toLowerCase().includes(term)) && // Ensure all search terms are in the name    
  //       (x.CollectionType == "Case" &&
  //         x.DropType == "Rare Drop")
  //     // ((x.TradeitEstimateCase !== true || tradeitHideUnavailable) || pricingSource !== "Tradeit")
  //   );

  //   const sortKeyMap = {
  //     unbox: pricingSource + 'ROI',
  //     cost: 'CollectionPrice' + pricingSource,
  //     profit: 'Profit' + pricingSource,
  //     invest: pricingSource + 'iROI',
  //     invest1M: pricingSource + '1MiROI',
  //     invest6M: pricingSource + '6MiROI',
  //     dropType: 'DropType',
  //     numListings: 'NumListings'
  //   };

  //   const sortKey = sortKeyMap[sortBy == "invest" ? investType == "1y" ? sortBy : investType == "6m" ? "invest6M" : "invest1M" : sortBy];
  //   const sortedItems = [...filteredItems].sort((a, b) => {
  //     let aValue = a[sortKey];
  //     let bValue = b[sortKey];

  //     if (sortBy === "unbox") {
  //       aValue *= a.CollectionType === "Collection" ? 0.001 : 1;
  //       bValue *= b.CollectionType === "Collection" ? 0.001 : 1;
  //     }

  //     if (sortBy === "dropType") {
  //       return aValue.localeCompare(bValue);
  //     }

  //     return bValue - aValue;
  //   });

  //   if (!sortDirection) {
  //     sortedItems.reverse();
  //   }

  //   return sortedItems;
  // }, [initialItems, sortBy, sortDirection]);

  const filteredDropItems = useMemo(() => {
    const searchTerms = inputValue.toLowerCase().split(' ').filter(term => term); // Split input into search terms

    // Convert dropItems to an array of objects (list of maps)
    const dropItemsArray = Object.values(dropItems);

    // Filter the array based on search terms
    const filteredItems = dropItemsArray.filter((item) =>
      // types.includes(item.CollectionType) &&
      searchTerms.every(term => item.Name.toLowerCase().includes(term)) // Ensure all search terms are in the name
      // (item.CollectionType == "Collection" &&
      //   item.DropType == "Active Drop")
      // ((item.TradeitEstimateCase !== true || tradeitHideUnavailable) || pricingSource !== "Tradeit")
    );

    return filteredItems; // Return the filtered array
  }, [dropItems, inputValue]);

  // console.log(filteredDropItems);



  function simulateCarePackage( updateData = true ) {
    if (items.length > 0) {
      const isCaseRare = Math.random() < 0.01;
      var tempCasePool = [];
      if (isCaseRare) {
        // Simulate a rare drop
        tempCasePool = items.filter(item => item.CollectionType === "Case" && item.DropType == "Rare Drop");
        // window.open("/item/" + randomItem.UrlName, "_self")
      } else {
        // Simulate a regular drop
        tempCasePool = items.filter(item => item.CollectionType === "Case" && item.DropType == "Active Drop");
        // window.open("/item/" + randomItem.UrlName, "_self")
      }
      const tempItemPool = items.filter(item => item.CollectionType === "Collection");
      const randomCase = tempCasePool[Math.floor(Math.random() * tempCasePool.length)];
      const randomItem = tempItemPool[Math.floor(Math.random() * tempItemPool.length)];
      const randomItemSeed = createSimulations();
      const caseCost = randomCase['CollectionPrice' + pricingSource];
      const exactItem = item(randomItemSeed, collectionData[randomItem.CollectionId]);
      const exactWear = wear(randomItemSeed, collectionData[randomItem.CollectionId]);
      const exactFloat = itemFloat(randomItemSeed, collectionData[randomItem.CollectionId]);
      const exactRarity = rarity(randomItemSeed, collectionData[randomItem.CollectionId]);
      const exactCost = collectionData[randomItem.CollectionId]["ItemCosts"][exactRarity][exactItem][wearCodeNames[exactWear]];
      const exactChance = (collectionData[randomItem.CollectionId]["PercentReceive"][exactRarity] / Object.keys(collectionData[randomItem.CollectionId]["ItemCosts"][exactRarity]).length) / Object.keys(collectionData).length;
      var exactCost2 = 0;
      const slot3Random = Math.random()
      const slot3 = slot3Random < 1 / 3 ? tempItemPool[Math.floor(Math.random() * tempItemPool.length)] : slot3Random < 2 / 3 ? "Random Graffiti" : "Charm Detachment Pack";
      var exactItem2 = null;
      var exactWear2 = null;
      var exactChance2 = null;
      var exactRarity2 = null;
      var randomItemSeed2 = null;
      if (slot3Random < 1 / 3) {
        randomItemSeed2 = createSimulations();
        exactItem2 = item(randomItemSeed2, collectionData[slot3.CollectionId]);
        exactWear2 = wear(randomItemSeed2, collectionData[slot3.CollectionId]);
        exactRarity2 = rarity(randomItemSeed2, collectionData[slot3.CollectionId]);
        exactCost2 = collectionData[slot3.CollectionId]["ItemCosts"][exactRarity2][exactItem2][wearCodeNames[exactWear2]];
        exactChance2 = (collectionData[slot3.CollectionId]["PercentReceive"][exactRarity2] / Object.keys(collectionData[slot3.CollectionId]["ItemCosts"][exactRarity2]).length) / Object.keys(collectionData).length;

      }
      var baseCasePrice = commonCases[0]['CollectionPrice' + pricingSource];
      const slot4 = "Random Graffiti";
      const carePackageData = [
        // randomCase,
        {
          "item": randomCase.Name,
          "image": randomCase.Image,
          "price": caseCost,
          "rarity": baseCasePrice < caseCost ? "Contraband" : baseCasePrice == caseCost ? "Covert" : baseCasePrice < caseCost * 1.5 ? "Classified" : baseCasePrice < caseCost * 2 ? "Restricted" : baseCasePrice < caseCost * 4 ? "Mil_Spec" : baseCasePrice < caseCost * 8 ? "Industrial" : "Consumer",
          "best": caseCost >= exactCost || caseCost >= exactCost2,
          "chance": (1 / tempCasePool.length) * (!isCaseRare ? 0.99 : 0.01),
        },
        {
          "item": exactItem,
          "wear": exactWear,
          "collection": randomItem.CollectionId,
          "image": collectionData[randomItem.CollectionId]["Items"][exactItem]["ImageUrl"],
          "price": exactCost,
          "float": exactFloat,
          "rarity": exactRarity,
          "best": exactCost >= caseCost || exactCost >= exactCost2,
          "chance": exactChance,
        },
        {
          "item": exactItem2 == null ? slot3 : exactItem2,
          "wear": exactItem2 == null ? null : exactWear2,
          "collection": exactItem2 == null ? null : randomItem.CollectionId,
          "image": exactItem2 == null ? randomImages[slot3 == "Random Graffiti" ? slot3 + Math.round(Math.random() * 10) : slot3] : collectionData[slot3.CollectionId]["Items"][exactItem2]["ImageUrl"],
          "price": exactItem2 == null ? slot3 == "Random Graffiti" ? 0.03 : 0 : exactCost2,
          "float": exactItem2 == null ? null : itemFloat(randomItemSeed2, collectionData[slot3.CollectionId]),
          "rarity": exactItem2 == null ? null : exactRarity2,
          "best": exactCost2 >= caseCost || exactCost2 >= exactCost,
          "chance": exactItem2 == null ? null : exactChance2,
        },
        {
          "item": slot4,
          "image": randomImages[slot4 + Math.round(Math.random() * 10)],
          "price": 0.03,
          "totalPrice": ((caseCost >= exactCost || caseCost >= exactCost2) ? caseCost : 0) + ((exactCost >= caseCost || exactCost >= exactCost2) ? exactCost : 0) + ((exactCost2 >= caseCost || exactCost2 >= exactCost) ? exactCost2 : 0),
        },
      ];
      if (updateData) {
        setCarePackageData(carePackageData);
      }

      
      return carePackageData;
      // console.log(randomCase);
      // console.log(exactItem + " (" + exactWear + ")");
      // console.log(exactItem2 == null ? slot3 == "Random Graffiti" ? slot3 + Math.round(Math.random() * 10) : slot3 : exactItem2 + " (" + exactWear2 + ")");
      // console.log(slot4);
    }
  }

  const simulateXTimes = async () => {
    let i = 0;
    // let localGlobalCaseCount = globalCaseCount;
    let localPastNumbers = [];
    const useLocalArray = delay == null || delay === '' || delay === 0 || delay === "0";

    while (i < numRepeats) {
      var newNumbers;
      if (delay != null && delay != '' && delay != 0 && delay !== "0") {
        newNumbers = simulateCarePackage();
      } else {
        newNumbers = simulateCarePackage(true);
      }

      i++;
      if (delay != null && delay != '' && delay != 0 && delay !== "0") {
        
        await new Promise(resolve => setTimeout(resolve, delay * 1000));
      }
      
    }

    // Update the state once at the end if using local array
    if (useLocalArray) {
      // newNumbers = createSimulations(false, newNumbers, localGlobalCaseCount);
      // setGlobalCaseCount(localGlobalCaseCount);
      // setNumbers(newNumbers);
      // setPastNumbers(prevPastNumbers => {
      //     const updatedPastNumbers = [...prevPastNumbers, ...localPastNumbers];
      //     return updatedPastNumbers;
      // });
    }
  };

  const simulateXMoney = async () => {
    let numSimulations = 999999;
    let filterFound = false;
    // let localGlobalCaseCount = globalCaseCount;

    let i = 0;

    let localPastNumbers = [];
    var value;
    var tempCarePackage = null;
    var item2 = null;
    while (i < numSimulations && !filterFound) {
      tempCarePackage = simulateCarePackage({ setCarePackageData: false });

      value = tempCarePackage[3].totalPrice;
      if (value / exchangeRate >= worth) {
        filterFound = true;
      }
      

      if (delay != null && delay != '' && delay != 0 && delay !== "0") {
        setCarePackageData(tempCarePackage);
        await new Promise(resolve => setTimeout(resolve, delay * 1000));
        
      }

    }
    if (delay == null || delay == '' || delay == 0 || delay == "0") {
      setCarePackageData(tempCarePackage);
    }
  };

  const simulateUntilFilter = async () => {
    let numSimulations = 999999;
    let filterFound = false;
    // let localGlobalCaseCount = globalCaseCount;

    let i = 0;

    let localPastNumbers = [];
    var newNumbers;
    var tempCarePackage = null;
    var item2 = null;
    while (i < numSimulations && !filterFound) {
      tempCarePackage = simulateCarePackage({ setCarePackageData: false });

      newNumbers = tempCarePackage[1];
      item2 = tempCarePackage[2];

      
      // newNumbers.push(localGlobalCaseCount);
      // const testCost = cost(newNumbers);
      // localGlobalCaseCount++;


      i++;
      if (collectionFilter != '') {
        if (collectionFilter == newNumbers.collection) {
          if (raritiesFilter != '') {
            if (raritiesFilter == newNumbers.rarity) {
              if (itemsFilter != '') {
                if (itemsFilter == newNumbers.item) {
                  if (wearsFilter != '') {
                    // console.log(wear(newNumbers));
                    // console.log(wearsFilter);
                    if (wearsFilter.replace('StatTrak™ ', '') == newNumbers.wear) {
                      if (wearsFilter.includes('StatTrak™ ')) {
                        if (newNumbers[3] < 0.1) {
                          filterFound = true;
                        }

                      } else {
                        filterFound = true;
                      }

                    }
                  }
                  else {
                    filterFound = true;
                  }
                }
              }
              else {
                filterFound = true;
              }
            }
          } else {
            filterFound = true;
          }
        }
      } else {
        filterFound = true;
      }

      if (item2.wear != null) {
        if (collectionFilter != '') {
          if (collectionFilter == item2.collection) {
            if (raritiesFilter != '') {
              if (raritiesFilter == item2.rarity) {
                if (itemsFilter != '') {
                  if (itemsFilter == item2.item) {
                    if (wearsFilter != '') {
                      // console.log(wear(newNumbers));
                      // console.log(wearsFilter);
                      if (wearsFilter.replace('StatTrak™ ', '') == item2.wear) {
                        if (wearsFilter.includes('StatTrak™ ')) {
                          if (item2[3] < 0.1) {
                            filterFound = true;
                          }
  
                        } else {
                          filterFound = true;
                        }
  
                      }
                    }
                    else {
                      filterFound = true;
                    }
                  }
                }
                else {
                  filterFound = true;
                }
              }
            } else {
              filterFound = true;
            }
          }
        } else {
          filterFound = true;
        }
      }

      if (delay != null && delay != '' && delay != 0 && delay !== "0") {
        setCarePackageData(tempCarePackage);
        await new Promise(resolve => setTimeout(resolve, delay * 1000));
        
      }

    }
    if (delay == null || delay == '' || delay == 0 || delay == "0") {
      setCarePackageData(tempCarePackage);
    }
    
  };

  useEffect(() => {
    simulateCarePackage();
  }, [collectionData]);

  function getRandomSeed() {
    const arr = new Uint32Array(1);
    window.crypto.getRandomValues(arr);
    return arr[0] / 0xFFFFFFFF;
  }

  function createSimulations(noAnimation = false, forceItem = null) {
    var newNumbers = [];
    for (let j = 0; j < 4; j++) {
      if (j === 2) {
        newNumbers.push(Math.round(getRandomSeed() * 2147483647) / 2147483647);
      } else {
        newNumbers.push(getRandomSeed());
      }
    }
    return newNumbers;
    // if (!noAnimation) {
    //   const divs = [];
    //   const numDivs = 50
    //   const allNewNumbers = [];
    //   const animationNumber = Math.floor(Math.random() * 5) + 1;
    //   for (let i = 0; i < numDivs; i++) {
    //     const newNumbers = [];
    //     for (let j = 0; j < 4; j++) {
    //       if (j === 2) {
    //         newNumbers.push(Math.round(Math.random() * 2147483647) / 2147483647);
    //       } else {
    //         newNumbers.push(Math.random());
    //       }
    //     }
    //     allNewNumbers.push(newNumbers)

    //   }

    //   if (forceItem != null) {
    //     allNewNumbers[animationNumber + 1] = forceItem
    //   }
    //   setCaseSimulates(allNewNumbers)

    //   for (let i = 0; i < numDivs; i++) {
    //     const margin = i * (isMobile ? 33 : 19); // Adjust the margin as needed
    //     divs.push(

    //       <Card className={`here ${isMobile ? "mob" : ""}animatedv${animationNumber}`} style={{
    //         //   paddingLeft: "18vw",
    //         animationDuration: `${Math.max(0, delay - 0.4)}s`,
    //         marginLeft: `${margin}vw`,
    //         transition: 'box-shadow 0.3s', position: "absolute",
    //         background: `linear-gradient(145deg, RGB(${otherRarityNames.hasOwnProperty(rarity(allNewNumbers[i])) ? redRarityNums[otherRarityNames[rarity(allNewNumbers[i])]] : redRarityNums[rarity(allNewNumbers[i])]}, ${otherRarityNames.hasOwnProperty(rarity(allNewNumbers[i])) ? greenRarityNums[otherRarityNames[rarity(allNewNumbers[i])]] : greenRarityNums[rarity(allNewNumbers[i])]}, ${otherRarityNames.hasOwnProperty(rarity(allNewNumbers[i])) ? blueRarityNums[otherRarityNames[rarity(allNewNumbers[i])]] : blueRarityNums[rarity(allNewNumbers[i])]}) 0%, black 100%)`,
    //       }}>
    //         <CardMedia
    //           sx={{ height: 140, width: isMobile ? '32vw' : '18vw', backgroundSize: "contain", }}
    //           image={caseData["Items"][item(allNewNumbers[i])]["ImageUrl"]}
    //         />
    //         <div style={{ position: 'absolute', top: isMobile ? '-4px' : 0, right: isMobile ? '4px' : '12px', padding: '4px', borderRadius: '4px 0 0 0', zIndex: 2 }}>

    //           <span>{formatNumber(cost(allNewNumbers[i]) / exchangeRate, true)}</span>
    //         </div>
    //         {/* <div style={{ position: 'absolute', bottom: '0px', right: '12px', padding: '4px', borderRadius: '4px 0 0 0', zIndex: 2 }}>
    //                           <span>1 in {(1 / (caseData["PercentReceive"][rarity(allNewNumbers[i])] / (rarity(allNewNumbers[i]) !== 'Contraband' ? Object.entries(caseData["ItemCosts"][rarity(allNewNumbers[i])]).length : Object.entries(caseData["KnifeCosts"]).length))).toFixed(1)}</span>
    //                       </div> */}
    //         <div style={{ position: 'absolute', top: isMobile ? '-4px' : '0px', left: isMobile ? '4px' : '12px', padding: '4px', borderRadius: '4px 0 0 0', zIndex: 2 }}>
    //           <span>{rarity(allNewNumbers[i]) !== "Contraband" ?
    //             (
    //               Object.entries(caseData['ItemCosts'][rarity(allNewNumbers[i])][item(allNewNumbers[i])]).length >
    //                 Object.entries(getWears(caseData['Items'][item(allNewNumbers[i])]['FloatMin'] / 100, caseData['Items'][item(allNewNumbers[i])]['FloatMax'] / 100))
    //                   .filter(([key, percent]) => percent !== 0).length + 1 ?
    //                 (allNewNumbers[i][3] > 0.1 ? '' : 'ST ') :
    //                 ''
    //             )
    //             :
    //             (
    //               Object.entries(caseData['KnifeCosts'][item(allNewNumbers[i])]).length >
    //                 Object.entries(getWears(caseData['Items'][item(allNewNumbers[i])]['FloatMin'] / 100, caseData['Items'][item(allNewNumbers[i])]['FloatMax'] / 100))
    //                   .filter(([key, percent]) => percent !== 0).length + 1 ?
    //                 (allNewNumbers[i][3] > 0.1 ? '' : 'ST ') :
    //                 ''
    //             )}{smallWearNames[wear(allNewNumbers[i])]}</span>
    //         </div>

    //       </Card>

    //     );
    //   }

    //   setCaseSimulateDivs(divs)
    //   setNumCaseSimulates((value) => value + 1);
    //   const elements = document.querySelectorAll('.here');

    //   // Remove the class responsible for the current animation
    //   elements.forEach((element) => {
    //     element.style.animationPlayState = 'paused';
    //     element.classList.remove('animatedv1');
    //     element.classList.remove('animatedv2');
    //     element.classList.remove('animatedv3');
    //     element.classList.remove('animatedv4');
    //     element.classList.remove('animatedv5');
    //     element.classList.remove('mobanimatedv1');
    //     element.classList.remove('mobanimatedv2');
    //     element.classList.remove('mobanimatedv3');
    //     element.classList.remove('mobanimatedv4');
    //     element.classList.remove('mobanimatedv5');
    //   });

    //   // Trigger a reflow to apply class changes immediately
    //   elements.forEach((element) => {
    //     void element.offsetWidth;
    //   });

    //   // Add the class with the new animation
    //   elements.forEach((element) => {
    //     element.style.animationPlayState = 'running';
    //     element.classList.add(`${isMobile ? "mob" : ""}animatedv${animationNumber}`);
    //   });
    //   return allNewNumbers[animationNumber + 1]
    // } else {
    // var newNumbers = [];
    // for (let j = 0; j < 4; j++) {
    //   if (j === 2) {
    //     newNumbers.push(Math.round(Math.random() * 2147483647) / 2147483647);
    //   } else {
    //     newNumbers.push(Math.random());
    //   }
    // }
    // return newNumbers;
    // }

    // const viewportWidth = window.innerWidth;
    // const indivItemWidth = (isMobile ? 33 : 19)
    // const pxItemWidth = viewportWidth * indivItemWidth/100;
    // const indivItemWidthNoMargin = (isMobile ? 32 : 18)
    // const pxItemWidthNoMargin = viewportWidth * indivItemWidthNoMargin/100;
    // const totalItemWidth = numDivs * pxItemWidth;
    // const animationPercent = animationNumber == 1 ? 0 : animationNumber == 2 ? 28 : animationNumber == 3 ? 67 : animationNumber == 4 ? 79 : 112;
    // const totalAnimationWidth = pxItemWidth * numDivs;

    // const middleBarSpot = width/2;
    // const pixelsMovedOver = (animationPercent / 1000) * totalAnimationWidth;
    // console.log(middleBarSpot);
    // console.log("Item number at animation, no middle: " + (animationPercent/indivItemWidth))
    // console.log("Item number at animation, w middle: " + (animationPercent + (middleBarSpot/viewportWidth * 100))/indivItemWidth)
    // const itemSelPos = Math.round((animationPercent + (middleBarSpot/viewportWidth * 100))/indivItemWidth)-2;
    // console.log(totalAnimationWidth);
    // // console.log("Pixels moved over: " + pixelsMovedOver);
    // // const numItemsBeforeMiddleBar = Math.floor(((animationPercent + (middleBarSpot/viewportWidth * 100))/indivItemWidth)-1);
    // // console.log("Items before middle: " + numItemsBeforeMiddleBar);


    // // console.log(totalAnimationWidth);
    // const itemPercent = animationPercent / indivItemWidth;
    // // const marginMoved = ind


  }

  function rarity(numbers, caseData) {
    var testNumber = numbers[0];
    for (const [rarityName, rarityChance] of Object.entries(caseData['PercentReceive'])) {
      if (testNumber > rarityChance) {
        testNumber -= rarityChance;
      } else {
        return rarityName;
      }
    }
  }

  function item(numbers, caseData) {
    var finalRarity = rarity(numbers, caseData);
    var whichItem = numbers[1];

    if (finalRarity != 'Contraband') {
      var itemsPossible = Object.entries(caseData['ItemCosts'][finalRarity]);
      var numItems = itemsPossible.length;
      var chancePerItem = 1 / numItems;
      for (const [itemName, itemStats] of itemsPossible) {
        if (whichItem > chancePerItem) {
          whichItem -= chancePerItem;
        } else {
          return itemName;
        }
      }
    } else {
      var itemsPossible = Object.entries(caseData['KnifeCosts']);
      var numItems = itemsPossible.length;
      var totalNumItems = caseData["TotalKnifeChance"];
      for (const [itemName, itemStats] of itemsPossible) {
        var thisItemChance = ((caseData["Items"][itemName]["ModifiedDropChance"] ?? 1)) / totalNumItems;
        if (whichItem > thisItemChance) {
          whichItem -= thisItemChance;
        } else {
          return itemName;

        }

      }
    }
  }

  function getWears(minFloat, maxFloat) {
    let fullFloatPercents = {};
    for (let wear of wearNames) {
      let floatRange = maxFloat - minFloat;
      let lowerRange = (wearRangeStarts[wear] ?? 0) - minFloat;
      let higherRange = (wearRanges[wear] ?? 0) - minFloat;
      let eFloatStart = lowerRange / floatRange;
      let eFloatEnd = higherRange / floatRange;
      let totalPercent = 0;
      let floatPercents = {};
      for (let wearName of wearNames) {
        let start = wearRangeStartDrops[wearName] ?? 0;
        let end = wearRanges[wearName] ?? 0;
        if (start < eFloatEnd && end > eFloatStart) {
          let realRange = end - start;
          let insideRange;
          let avgRangeFloat;
          if (eFloatStart < start && eFloatEnd > end) {
            insideRange = end - start;
            avgRangeFloat = (insideRange * floatRange) / 2 + minFloat + start * floatRange;
          } else if (eFloatStart > start && eFloatEnd < end) {
            insideRange = eFloatEnd - eFloatStart;
            avgRangeFloat = (insideRange * floatRange) / 2 + minFloat + start * floatRange;
          } else if (eFloatStart < start) {
            insideRange = eFloatEnd - start;
            avgRangeFloat = (insideRange * floatRange) / 2 + minFloat + start * floatRange;
          } else {
            insideRange = end - eFloatStart;
            avgRangeFloat = (insideRange * floatRange) / 2 + minFloat + start * floatRange;
          }
          let percentRange = insideRange / realRange;
          let percent = percentRange * wearDropRates[wearName];
          totalPercent += percent;
          floatPercents[avgRangeFloat.toFixed(4)] = percent;
        }
      }
      let floatPercentsKeys = Object.keys(floatPercents);
      let totalAvgFloat = 0;
      let totalPercents = 0;
      for (let stringPercent of floatPercentsKeys) {
        let percent = floatPercents[stringPercent];
        totalAvgFloat += (percent / totalPercent) * parseFloat(stringPercent);
        totalPercents += percent;
      }
      fullFloatPercents[wear] = (totalPercents);
    }
    return fullFloatPercents;
  }

  function wear(numbers, caseData) {
    var minFloat = caseData['Items'][item(numbers, caseData)]['FloatMin'];
    var maxFloat = caseData['Items'][item(numbers, caseData)]['FloatMax'];
    if (minFloat == null) {
      return null;
    }
    var wearChances = getWears(minFloat / 100, maxFloat / 100);
    var whichWear = numbers[2];
    for (const [wearName, wearChance] of Object.entries(wearChances)) {

      if (whichWear > wearChance) {
        whichWear -= wearChance;
      } else {
        return wearName;

      }
    }
  }

  function whereWearsPlaced(minFloat, maxFloat) {
    const allWears = {};
    for (const wearName of wearNames) {
      const wearStart = wearRangeStartDrops[wearName];
      const wearEnd = wearRanges[wearName];
      const adjustedStart = wearStart * (maxFloat - minFloat) + minFloat;
      const adjustedEnd = wearEnd * (maxFloat - minFloat) + minFloat;
      allWears[wearName] = { 'start': adjustedStart, 'end': adjustedEnd, 'size': ((adjustedEnd - adjustedStart) * 100000000).toFixed(0), 'height': wearDropRates[wearName] / ((adjustedEnd - adjustedStart) * 100), 'rawRate': wearDropRates[wearName] };
    }
    // console.log(filterWearsInRange(allWears, 0, 0.2));
    return allWears;
  }

  function itemFloat(numbers, caseData) {
    var minFloat = caseData['Items'][item(numbers, caseData)]['FloatMin'];
    var maxFloat = caseData['Items'][item(numbers, caseData)]['FloatMax'];
    if (minFloat == null) {
      return null;
    }
    var whichWear = numbers[2];
    return findWearAtFloat(whichWear, minFloat, maxFloat);
  }

  function findWearAtFloat(givenFloat, minFloat, maxFloat) {
    // const wearRange = (maxFloat - minFloat) / 100;
    // return (givenFloat * wearRange) + minFloat / 100;
    var whichWear = givenFloat;
    // var wearChances = getWears(minFloat / 100, maxFloat / 100);
    const whereWears = whereWearsPlaced(minFloat / 100, maxFloat / 100);
    for (const [wearName, wearChance] of Object.entries(wearDropRates)) {
      if (whichWear > wearChance) {
        whichWear -= wearChance;
      } else {
        const howFarIntoRange = whichWear / wearChance;
        const wearStartDrop = whereWears[wearName]['start'];
        const wearEndDrop = whereWears[wearName]['end'];
        const wearRange = wearEndDrop - wearStartDrop;
        return wearStartDrop + (howFarIntoRange * wearRange);
        // return wearName;

      }
    }
  }

  function caseNum(numbers) {
    return numbers[4];
  }

  useEffect(() => {
    const fetchData = async () => {
      let url = '/pastData/allTrackedCases.json';
      if (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1") {
        url = 'http://localhost:8080/pastData/allTrackedCases.json';
      }

      const response = await fetch(url);
      const text = await response.text();
      var processedData = JSON.parse(text);
      processedData = [...processedData].sort((a, b) => {
        let aValue = a['CollectionPrice' + pricingSource];
        let bValue = b['CollectionPrice' + pricingSource];

        return bValue - aValue;
      });
      setInitialItems(processedData);


      const tempItemPool = processedData.filter(item => item.CollectionType === "Collection" && item.DropType == "Active Drop");

      var allCollectionData = {};
      var allItems = {};
      var totalDropValue = 0;

      for (let i = 0; i < tempItemPool.length; i++) {
        const item = tempItemPool[i];
        let url = "/case/" + pricingSource + "/" + item.CollectionId + "/data.json";

        if (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1") {
          url = 'http://localhost:8080' + url;
        }
        const response = await fetch(url);
        const data = await response.text();
        const tempCollectionData = JSON.parse(data);

        totalDropValue += item[pricingSource + "ROI"] * 0.01;
        allCollectionData[item.CollectionId] = tempCollectionData;

        for (const [itemName, itemData] of Object.entries(tempCollectionData["Items"])) {
          allItems[itemName] = itemData;
          const tempRarity = rarity_dict[itemData["Rarity"]];
          if ((window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1") && !itemData["ImageUrl"].includes("http")) {
            allItems[itemName]["ImageUrl"] = 'http://localhost:8080' + itemData["ImageUrl"];
          }
          allItems[itemName]["Rarity"] = tempRarity;
          allItems[itemName]["Cost"] = tempCollectionData["ItemCosts"][tempRarity][itemName];
          allItems[itemName]["ROI"] = tempCollectionData["ItemROIs"][tempRarity][itemName]["totalItemValue"][
            investType == "1y" ? "ROI" : investType == "6m" ? "6M_ROI" : "1M_ROI"
          ];
          allItems[itemName]["ItemChance"] = (
            tempCollectionData["PercentReceive"][tempRarity] / Object.keys(tempCollectionData["ItemCosts"][tempRarity]).length
          ) / (tempItemPool.length);

          allItems[itemName]["WearChances"] = getWears(tempCollectionData["Items"][itemName]["FloatMin"] / 100, tempCollectionData["Items"][itemName]["FloatMax"] / 100);
          // remove wear chances that are 0
          for (const [wearName, wearChance] of Object.entries(allItems[itemName]["WearChances"])) {
            if (wearChance == 0) {
              delete allItems[itemName]["WearChances"][wearName];
            }
          }
        }
      }

      const sortedItems = Object.entries(allItems)
        .sort(([, a], [, b]) => {
          if (a.Cost["$"] !== b.Cost["$"]) {
            return b.Cost["$"] - a.Cost["$"];
          }
          return b.Cost["totalItemValue"] - a.Cost["totalItemValue"];
        })
        .map(([key, value]) => ({ ...value, name: key }));

      const commonCasesTemp = processedData.filter(
        (x) => x.DropType == "Active Drop" && x.CollectionType == "Case"
      );
      const rareCasesTemp = processedData.filter(
        (x) => x.DropType == "Rare Drop" && x.CollectionType == "Case"
      );

      const c = (
        (commonCasesTemp.length > 0
          ? commonCasesTemp.reduce((sum, item) => {
            const collectionPriceKey = `CollectionPrice${pricingSource}`;
            return sum + (item[collectionPriceKey] || 0);
          }, 0) / commonCasesTemp.length
          : 0) * 0.99 +
        (rareCasesTemp.length > 0
          ? rareCasesTemp.reduce((sum, item) => {
            const collectionPriceKey = `CollectionPrice${pricingSource}`;
            return sum + (item[collectionPriceKey] || 0);
          }, 0) / rareCasesTemp.length
          : 0) * 0.01
      );

      setCommonCases(commonCasesTemp);
      setRareCases(rareCasesTemp);
      setActiveDropValue(totalDropValue / tempItemPool.length);
      setDropItems(sortedItems);
      setCollectionData(allCollectionData);

      // --- E_skin ---
      let E_skin = 0;
      for (const item of Object.values(allItems)) {
        E_skin += item.ItemChance * item.Cost["totalItemValue"];
      }
      console.log("Average single skin value (E_skin):", E_skin);

      // --- E_bonus ---
      const itemsArray = Object.values(allItems);

      function computeEbonusFromCasePool(casePool, weight) {
        let subtotal = 0;
        for (const caseItem of casePool) {
          const caseValue = caseItem[`CollectionPrice${pricingSource}`] || 0;

          for (let i = 0; i < itemsArray.length; i++) {
            for (let j = 0; j < itemsArray.length; j++) {
              const skin1 = itemsArray[i];
              const skin2 = itemsArray[j];

              const p1 = skin1.ItemChance;
              const p2 = skin2.ItemChance;

              const v1 = skin1.Cost["totalItemValue"];
              const v2 = skin2.Cost["totalItemValue"];

              const sumBestTwo = caseValue + v1 + v2 - Math.min(caseValue, v1, v2);
              subtotal += p1 * p2 * sumBestTwo;
            }
          }
        }

        return (subtotal / casePool.length) * weight;
      }

      const E_bonus_common = computeEbonusFromCasePool(commonCasesTemp, 0.99);
      const E_bonus_rare = computeEbonusFromCasePool(rareCasesTemp, 0.01);
      const E_bonus = E_bonus_common + E_bonus_rare;

      // --- E_package ---
      const expectedPackageReturn = (2 / 3) * (c + E_skin) + (1 / 3) * E_bonus;
      console.log("Expected Care Package Return:", expectedPackageReturn);

      setExpectedReturn(expectedPackageReturn);
    };

    fetchData();
  }, [pricingSource]);


  // useEffect(() => {
  //   const fetchData = async () => {

  //     let url = '/pastData/allTrackedCases.json';

  //     if (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1") {
  //       url = 'https://localhost:44396/jsondata';
  //     }

  //     const response = await fetch(url);
  //     const text = await response.text();
  //     // const processedText = `[${text.slice(0, -3)}]`;
  //     const processedData = JSON.parse(text);
  //     // console.log(processedData);




  //     // setItems(processedData.filter((x) => types.includes(x.CollectionType)));
  //     setInitialItems(processedData);

  //     //   setData(finalData)
  //   };
  //   fetchData();
  // }, []);

  const [isMobileLayout, setIsMobileLayout] = useState(false);

  useEffect(() => {
    // Initial calculation
    handleResize();

    // Add event listener for resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);



  const handleResize = () => {
    // Calculate aspect ratio 
    const glanceInfoSize = window.innerWidth * 2 / 11

    // Define the threshold value for aspect ratio to differentiate between mobile and desktop
    const glanceInfoThreshold = 215; // Adjust this value based on your needs

    // Set the state based on the aspect ratio
    setIsMobileLayout(glanceInfoSize < glanceInfoThreshold);
  };
  // useEffect(() => {
  //   setItems(initialItems.filter((x) => types.includes(x.CollectionType)).filter((x) => x.Name.toLowerCase().includes(inputValue.toLowerCase())).sort((a,b) => sortBy === "unbox" ? (pricingSource === "Buff" ?  (b['BuffROI'] - a['BuffROI']) : (b['SteamROI'] - a['SteamROI'])): (pricingSource === "Buff" ?  (b['BuffiROI'] - a['BuffiROI']) : (b['SteamiROI'] - a['SteamiROI']))))
  // }, [types, inputValue, sortBy])
  const handleTradeitHide = (sortTest) => {
    setTradeitHideUnavailable(sortTest);
    localStorage.setItem('tradeitHide', sortTest.toString());
    // setSelectedItem(item);
    // setSelectedRarity(rarity)
  };

  const handleSort = (event, sort) => {
    setSortBy(sort.props.value);
    localStorage.setItem('sortBy', sort.props.value);
    // setSelectedItem(item);
    // setSelectedRarity(rarity)
  };

  const handleSortDirection = (sortTest) => {
    setSortDirection(sortTest);
    localStorage.setItem('sortDirection', sortTest.toString());
    // setSelectedItem(item);
    // setSelectedRarity(rarity)
  };

  const handleShowUnbox = (sortTest) => {
    setShowUnbox(sortTest);
    localStorage.setItem('showUnbox', sortTest.toString());
    // setSelectedItem(item);
    // setSelectedRarity(rarity)
  };

  const handleShowProfit = (sortTest) => {
    setShowProfit(sortTest);
    localStorage.setItem('showProfit', sortTest.toString());
    // setSelectedItem(item);
    // setSelectedRarity(rarity)
  };

  const handleShowInvest = (sortTest) => {
    setShowInvest(sortTest);
    localStorage.setItem('showInvest', sortTest.toString());
    // setSelectedItem(item);
    // setSelectedRarity(rarity)
  };


  const handleShowListings = (sortTest) => {
    setShowListings(sortTest);
    localStorage.setItem('showListings', sortTest.toString());
    // setSelectedItem(item);
    // setSelectedRarity(rarity)
  };

  const handleChange = (type) => {
    const typeValue = type.target.value;
    let newTypes;

    if (!types.includes(typeValue)) {
      newTypes = [...types, typeValue];
      if (typeValue === "Graffiti") {
        newTypes.push("Pins");
      }
      // if (typeValue === "Past Operation") {
      //   newTypes.push("Armory");
      // }
    } else {
      newTypes = types.filter(x => x !== typeValue);
      if (typeValue === "Graffiti") {
        newTypes = newTypes.filter(x => x !== "Pins");
      }
      // if (typeValue === "Past Operation") {
      //   newTypes = newTypes.filter(x => x !== "Armory");
      // }
    }

    localStorage.setItem('cachedTypes', JSON.stringify(newTypes));
    setTypes(newTypes);
  };
  // const handleChange = (type) => {

  //   if (!types.includes(type.target.value)) {
  //     localStorage.setItem('cachedTypes', JSON.stringify([...types, type.target.value, "Pins"]));
  //     setTypes([...types, type.target.value, "Pins"])
  //     // Find all elements with a certain class
  //     const elements = document.querySelectorAll(type.target.value);

  //     // Loop through each element and hide it
  //     elements.forEach((element) => {
  //       element.style.display = 'initial'; // or 'hidden' depending on your needs
  //     });
  //   } else {
  //     localStorage.setItem('cachedTypes', JSON.stringify(types.filter((x) => x !== type.target.value && x !== "Pins")));
  //     setTypes(types.filter((x) => x !== type.target.value && x !== "Pins"))
  //     // Find all elements with a certain class
  //     const elements = document.querySelectorAll(type.target.value);

  //     // Loop through each element and hide it
  //     elements.forEach((element) => {
  //       element.style.display = 'none'; // or 'hidden' depending on your needs
  //     });
  //   }

  //   // if (type.target.value == "Graffiti") {
  //   //   if (!types.includes(type.target.value)) {
  //   //     localStorage.setItem('cachedTypes', JSON.stringify([...types, type.target.value, "Pins"]));
  //   //     setTypes([...types, type.target.value, "Pins"])
  //   //   } else {
  //   //     localStorage.setItem('cachedTypes', JSON.stringify(types.filter((x) => x !== type.target.value && x !== "Pins")));
  //   //     setTypes(types.filter((x) => x !== type.target.value && x !== "Pins"))
  //   //   }
  //   // } else {
  //   //   if (!types.includes(type.target.value)) {
  //   //     localStorage.setItem('cachedTypes', JSON.stringify([...types, type.target.value]));
  //   //     setTypes([...types, type.target.value])
  //   //   } else {
  //   //     localStorage.setItem('cachedTypes', JSON.stringify(types.filter((x) => x !== type.target.value)));
  //   //     setTypes(types.filter((x) => x !== type.target.value))
  //   //   }
  //   // }

  // };
  useEffect(() => {
    const fetchCurrencies = async () => {
      try {
        const cachedCurrencies = localStorage.getItem('currencies');
        const cachedTimestamp = localStorage.getItem('currenciesTimestamp');

        if (
          cachedCurrencies &&
          cachedTimestamp &&
          Date.now() - Number(cachedTimestamp) < CACHE_DURATION
        ) {
          setCurrencies(JSON.parse(cachedCurrencies));
        } else {
          let url = '/currencies.json'

          if (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1") {
            url = 'https://csroi.com/currencies.json';
          }

          const response = await fetch(url);
          const data = await response.json();
          const currencyList = Object.keys(data);
          setCurrencies(currencyList);

          localStorage.setItem('currencies', JSON.stringify(currencyList));
          localStorage.setItem('currenciesTimestamp', Date.now().toString());
        }
      } catch (error) {
        console.error('Failed to fetch currencies:', error);
      }
    };

    const updateExchangeRateToUSD = async () => {
      try {
        const cachedCurrency = localStorage.getItem('currency');
        const cachedUSDExchangeRate = localStorage.getItem('usdExchangeRate');
        const cachedTimestamp = localStorage.getItem('usdExchangeRateTimestamp');
        // const cachedAllCases = localStorage.getItem('allCases');
        // const cachedAllLowCosts = localStorage.getItem('allLowCosts');

        if (
          cachedCurrency &&
          currency == cachedCurrency &&
          cachedUSDExchangeRate &&
          cachedTimestamp &&
          Date.now() - Number(cachedTimestamp) < CACHE_DURATION
        ) {
          setCurrency(cachedCurrency);
          // setPricingSource('USD');
          setExchangeRate(cachedUSDExchangeRate);
          // setAllCases(JSON.parse(cachedAllCases));
          // setAllLowCosts(JSON.parse(cachedAllLowCosts));
        } else {
          const response = await fetch(
            `https://cdn.jsdelivr.net/npm/@fawazahmed0/currency-api@latest/v1/currencies/${currency.toLowerCase()}.json`
          );
          const data = await response.json();
          const usdExchangeRate = data[currency.toLowerCase()]['usd'];
          // console.log(data);

          console.log(currency);
          console.log(usdExchangeRate);

          setCurrency(currency);
          // setPricingSource('USD');
          setExchangeRate(usdExchangeRate);

          localStorage.setItem('currency', currency);
          localStorage.setItem('usdExchangeRate', usdExchangeRate);
          localStorage.setItem('usdExchangeRateTimestamp', Date.now().toString());

          //Piggybacking data for all cases to update daily:
          // let caseurl = '/allCases.json'

          // if (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1") {
          //   caseurl = 'https://csroi.com/allCases.json';
          // }

          // const responseAllCases = await fetch(caseurl);
          // const textAllCases = await responseAllCases.text();

          // const processedText = `[${text.slice(0, -3)}]`;
          // const processedDataAllCases = JSON.parse(textAllCases);

          //Piggybacking data for all cases to update daily:
          // let costUrl = '/allLowCosts.json'

          // if (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1") {
          //   costUrl = 'https://csroi.com/allLowCosts.json';
          // }

          // const responseLowCosts = await fetch(costUrl);
          // const textLowCosts = await responseLowCosts.text();

          // // const processedText = `[${text.slice(0, -3)}]`;
          // const processedDataLowCosts = JSON.parse(textLowCosts);

          // setAllCases(processedDataAllCases);
          // setAllLowCosts(processedDataLowCosts);
          // console.log(textAllCases);
          // console.log(textLowCosts);
          // localStorage.setItem('allCases', textAllCases);
          // localStorage.setItem('allLowCosts', textLowCosts);
        }
      } catch (error) {
        console.error('Failed to update exchange rate to USD:', error);
      }
    };

    fetchCurrencies();
    updateExchangeRateToUSD();
  }, [currency]);

  useEffect(() => {
    setCurrencySymbol(getSymbolFromCurrency(currency));
  }, [currency]);

  useEffect(() => {
    if (!window.location.href.includes('localhost')) {
      ReactGA.send({ hitType: "pageview", page: "/carepackage", title: "Care Package" });
      // ReactGA.pageview(window.location.pathname + window.location.search);
    }
  }, []);

  // const toggleDrawer = (open) => (event) => {
  //   setDrawerOpen(!drawerOpen);
  // };



  const theme = createTheme({
    palette: {
      mode: 'dark',
    },
    typography: {
      fontFamily: 'Barlow',
    }
  });

  const ColumnCountContext = React.createContext();

  const [drawerOpen, setDrawerOpen] = useState(false);



  const handleCurrencyChange = (event, newCurrency) => {
    setCurrency(newCurrency);
  };

  const handlePricingSourceChange = (event, newPricingSource) => {
    localStorage.setItem('pricingSource', newPricingSource.props.value)
    setPricingSource(newPricingSource.props.value);
  };


  const handleInputChange = (event, newInputValue) => {
    localStorage.setItem('search', newInputValue);
    setInputValue(newInputValue);
  };

  const handleSearchChange = (event, newInputValue, reason) => {

    // if (reason == "selectOption") {
    // window.open("/item/" + newInputValue.UrlName, "_self")
    setSelectedItem(newInputValue);

    // }


  };

  const handleBlur = () => {
    if (inputValue.trim() !== '') {
      setSelectedValue(inputValue);
    }
  };

  const handleClose = (event, reason) => {
    if (reason === 'selectOption') {
      setSelectedValue(inputValue);
    }
  };

  const [selectedItem, setSelectedItem] = useState(null);

  const handleItemClick = (item) => {
    // console.log('here:');
    // console.log(item);
    // console.log(rarity);
    setSelectedItem(item);
    // if (rarity !== "Contraband") {
    //   if (Object.keys(caseData.ItemCosts[rarity][item]).length > 1) {
    //     setSelectedItem(item);
    //     setSelectedRarity(rarity)
    //   } else {
    //     var itemLink = caseData["IsCSFloat"] ? csfloatLink((caseData.SouvenirPackName != null ? "Souvenir " : "") + item) : caseData["ISTradeit"] ? tradeitLink((caseData.SouvenirPackName != null ? "Souvenir " : "") + item) : caseData["IsBuff"] ? buffLink((caseData.SouvenirPackName != null ? "Souvenir " : "") + item) : caseData["IsSkinport"] ? skinportLink((caseData.SouvenirPackName != null ? "Souvenir " : "") + item, caseData) : steamLink((caseData.SouvenirPackName != null ? "Souvenir " : "") + item);
    //     window.open(itemLink, '_blank')
    //   }
    // } else {
    //   setSelectedItem(item);
    //   setSelectedRarity(rarity)
    // }

  };

  const handleCloseOverlay = () => {
    setSelectedItem(null);
  };
  const MemoizedCaseGridItem = React.memo(CaseGridItem);

  function Cell({ columnIndex, rowIndex, style, items, isCommon = false }) {
    const columnCount = React.useContext(ColumnCountContext);

    const index = rowIndex * (columnCount) + columnIndex;
    const item = items[index];
    return item ? (
      <div style={style}>
        <div style={{ margin: '2px', flex: 6, flexWrap: 'wrap', justifyContent: 'center', padding: 0 }}>
          <MemoizedCaseGridItem
            key={item.id}
            item={item}
            index={(1 / items.length) * (isCommon ? 0.99 : 0.01)}
            pricingSource={pricingSource}
            currency={currency}
            exchangeRate={exchangeRate}
            sortBy={sortBy}
            isMobileLayout={isMobileLayout}
            showUnbox={showUnbox}
            showProfit={showProfit}
            showInvest={showInvest}
            numListings={item.NumListings}
            showListings={showListings}
            investType={investType}
            isCaseEstimate={pricingSource == "Tradeit" ? item?.TradeitEstimateCase : false}
          />
        </div>
      </div>
    ) : null;

  }

  // function Cell({ columnIndex, rowIndex, style }) {
  //   const columnCount = React.useContext(ColumnCountContext);

  //   const index = rowIndex * (columnCount) + columnIndex;
  //   const item = items[index];
  //   return item ? (
  //     <div style={style}>
  //       <div style={{ margin: '2px', flex: 6, flexWrap: 'wrap', justifyContent: 'center', padding: 0, maxWidth: '800px' }}>
  //         <CaseGridItem
  //           key={item.id}
  //           item={item}
  //           index={index}
  //           pricingSource={pricingSource}
  //           currency={currency}
  //           exchangeRate={exchangeRate}
  //           sortBy={sortBy}
  //           isMobileLayout={isMobileLayout}
  //           showUnbox={showUnbox}
  //           showProfit={showProfit}
  //           showInvest={showInvest}
  //           numListings={item.NumListings}
  //           showListings={showListings}
  //         />
  //       </div>
  //     </div>
  //   ) : null;
  // }


  return (
    <ThemeProvider theme={theme}>
      <CookieConsent
        location="bottom"
        buttonText="I understand"
        cookieName="garequest"
        style={{ background: "#2B373B", zIndex: 99999 }}
        buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
        expires={150}
      >
        This website uses cookies for anonymous Google Analytics. By continuing to use this website, you consent to the use of cookies.
      </CookieConsent>
      <Helmet>
        {/* Page Title */}
        <title>CSROI.com | Find and Simulate the Best CS2 Cases, Capsules & Armory Rewards for Unboxing & Investing</title>

        {/* Meta Description */}
        <meta
          name="description"
          content="Analyze, simulate unboxing and compare the best CS2 cases, capsules, armory rewards, and more! Discover expected ROI, unboxing profit chances, investment trends, historical market data, rarity values and more!"
        />

        {/* Keywords (Less important for SEO now but still useful) */}
        <meta name="keywords" content="CS2, simulate, CS:GO, ROI, case, capsule, souvenir, sticker, armory, stars, investment, pin, graffiti, autographs, profit, unboxing, simulator, history, music kit, collection, operation" />

        {/* Open Graph (Facebook) */}
        <meta property="og:title" content="CSROI.com | Best CS2 Cases & Capsules to Unbox or Invest" />
        <meta property="og:description" content="Find the best CS2 cases, capsules, and armory rewards for unboxing or investment. Simulate Unboxing, view ROI data, profit chances, historical market trends, and rarity values." />
        <meta property="og:image" content="https://csroi.com/logo512.png" />
        <meta property="og:url" content="https://csroi.com" />

        {/* Twitter Cards */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="CSROI.com | CS2 Case ROI & Investment Analysis" />
        <meta name="twitter:description" content="Discover the best CS2 cases, capsules, and armory rewards for unboxing & investing. Simulate Unboxing, ROI insights, profit chances, historical trends, and more!" />
        <meta name="twitter:image" content="https://csroi.com/logo512.png" />

        {/* Canonical URL */}
        <link rel="canonical" href="https://csroi.com" />

        {/* Schema Markup for SEO */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "WebPage",
            "name": "CSROI.com - CS2 Case & Capsule ROI Rankings",
            "description": "Analyze, simulate unboxing and compare the best CS2 cases, capsules, and armory rewards for investing or unboxing. See expected ROI, profit chances, market trends, rarity values and more!",
            "url": "https://csroi.com",
            "image": "https://csroi.com/logo512.png",
            "publisher": {
              "@type": "Organization",
              "name": "CSROI",
              "logo": {
                "@type": "ImageObject",
                "url": "https://csroi.com/logo512.png"
              }
            }
          })}
        </script>
      </Helmet>
      <Box sx={{ display: 'flex', padding: 0, transition: "width 5s infinite alternate, height 5s infinite alternate", }}>
        {selectedItem && (
          <Overlay
            item={selectedItem}
            onClose={handleCloseOverlay}
            caseData={collectionData[selectedItem.ItemCollectionId]}
            initialItems={initialItems}
            pricingSource={pricingSource}
            currency={currency}
            exchangeRate={exchangeRate}
            sortBy={sortBy}
            investType={investType}
            isMobileLayout={isMobileLayout}
            showUnbox={showUnbox}
            showProfit={showProfit}
            showInvest={showInvest}
            showListings={showListings}
            selectedItem={selectedItem}
          />
        )}
        <CssBaseline />
        <Drawer
          sx={{
            display: isMobileLayout ? 'none' : 'initial',
            width: drawerWidth,
            // flexShrink: 0,

            '& .MuiDrawer-paper': {
              width: drawerWidth,
              boxSizing: 'border-box',
            },
          }}
          variant="permanent"
          anchor="left"
        // open={openDrawer}
        >
          <Scrollbars
            autoHide
            // autoHideTimeout={1000}
            // autoHideDuration={200}
            style={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}
            renderView={props => <div {...props} style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }} />}
            renderThumbVertical={({ style }) => (
              <div style={{ ...style, backgroundColor: 'lightgray', borderRadius: '4px' }} />
            )}
          >
            <DrawerHeader sx={{ paddingTop: '12px', paddingBottom: '12px' }}>
              <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', margin: 'auto', marginBottom: '6px', marginTop: '6px', }}>
                <img src="/csroi.png" class="p-2" style={{ maxWidth: '280px', width: '100%', height: "auto", objectFit: 'contain', margin: 'auto' }} />

              </div>


            </DrawerHeader>
            <Divider style={{ borderWidth: '2px' }} />
            {/* <h5 style={{ textAlign: 'center', maxHeight: '180px', padding: '4px' }}>
              Welcome to the all new CSROI.com!<br /><br />
              Featuring a new overhauled React UI, full currency conversion, a new Investing ROI, upgraded simulator, among many more!<br /><br />
              Any feedback is appreciated <a href="https://twitter.com/BrongoCS" target='_blank'>@BrongoCS</a>
            </h5> */}
            {/* <Divider /> */}
            {/* <iframe width={drawerWidth} height="180" src="https://www.youtube.com/embed/mMCqwpVC2Zc?si=1DY9BEJKcLe66qy3" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share;" allowFullScreen ></iframe> */}
            <Link to='/' style={{ textDecoration: 'none', color: 'inherit' }}>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', padding: '4px 16px 4px 16px', cursor: 'pointer', backgroundColor: 'rgba(255, 255, 255, 0.12)' }}>
                <p>
                  Rankings

                </p>
              </div>
            </Link>
            <Divider style={{ borderWidth: '2px' }} />
            <Link to='/armory' style={{ textDecoration: 'none', color: 'inherit' }}>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', padding: '4px 16px 4px 16px', cursor: 'pointer' }}>
                <p style={{ lineHeight: '0.9' }}>
                  Armory
                  <br />
                  <span style={{ fontSize: '0.7rem' }}>Find the best way to spend YOUR Armory Stars</span>
                </p>
                {/* <Chip label="NEW" variant="outlined" size='small' sx={{ color: 'white', border: '1px white solid', fontSize: '0.8rem', height: '24px', width: '48px', padding: '0' }} /> */}

              </div>
            </Link>
            <Divider />
            <Link to='/deals' style={{ textDecoration: 'none', color: 'inherit' }}>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', padding: '4px 16px 4px 16px', cursor: 'pointer' }}>
                <p style={{ lineHeight: '0.9' }}>
                  Deals
                  <br />
                  <span style={{ fontSize: '0.7rem' }}>Resell Skins to get up to 1.5x Steam Wallet Balance!</span>
                </p>
                <Chip label="NEW" variant="outlined" size='small' sx={{ color: 'white', border: '1px white solid', fontSize: '0.8rem', height: '24px', width: '48px', padding: '0' }} />
              </div>
            </Link>
            <Divider />
            <Link to='/inventory' style={{ textDecoration: 'none', color: 'inherit' }}>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', padding: '4px 16px 4px 16px', cursor: 'pointer' }}>
                <p style={{ lineHeight: '0.9' }}>
                  Inventory
                  <br />
                  <span style={{ fontSize: '0.7rem', }}>Find YOUR Unboxing and Investing ROI!</span>
                </p>
                {/* <Chip label="NEW" variant="outlined" size='small' sx={{ color: 'white', border: '1px white solid', fontSize: '0.8rem', height: '24px', width: '48px', padding: '0' }} /> */}
              </div>
            </Link>
            <Divider />
            <Link to='/charmcolors' style={{ textDecoration: 'none', color: 'inherit' }}>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', padding: '4px 16px 4px 16px', cursor: 'pointer', }}>
                <p style={{ lineHeight: '0.9' }}>
                  Charm Colors
                  <br />
                  <span style={{ fontSize: '0.7rem' }}>Find the color and pattern of charm to match your skin!</span>
                </p>
                {/* <Chip label="NEW" variant="outlined" size='small' sx={{ color: 'white', border: '1px white solid', fontSize: '0.8rem', height: '24px', width: '48px', padding: '0' }} /> */}

              </div>
            </Link>

            <Divider />


            {/* <p style={{textAlign: 'center', margin: '0'}}> */}
            <div style={{ flex: '1' }}></div>


            <Divider />

            {/* {(!isMobileLayout || openSettings) && <div style={{ display: 'flex', marginTop: '8px', marginBottom: '8px' }}> */}

            <FormControl sx={{ fontFamily: "'Barlow', 'Roboto', sans-serif", padding: '0 12px 8px 12px', marginTop: '12px', width: '100%' }}>
              <InputLabel id="pricing-source-label" sx={{ paddingLeft: '14px' }}>Pricing Source</InputLabel>
              <Select
                //  renderInput={(params) => <TextField {...params} label="Pricing Source" />}

                labelId="pricing-source-label"
                id="pricing-source"
                value={pricingSource}
                label="Pricing Source"
                onChange={handlePricingSourceChange}

              >
                <MenuItem value="Steam">Steam</MenuItem>
                <MenuItem value="Buff">
                  <div>
                    Buff.163 / UUPin<Tooltip title="Buff.163 prices are preferred, but UUPin prices are used when Buff.163 prices are unavailable (e.g., containers) or when there is a significant price difference." sx={{ margin: 'auto' }}>

                      <HelpOutlineIcon className="iconSVG" sx={{ width: isMobileLayout ? '16px' : '20px', height: isMobileLayout ? '16px' : '20px', margin: 'auto', marginLeft: '4px', marginBottom: '-4px' }} fontSize='large' />
                    </Tooltip>
                  </div>
                </MenuItem>
                <MenuItem value="CSFloat">CSFloat</MenuItem>
                <MenuItem value="Skinport">Skinport</MenuItem>
                <MenuItem value="Tradeit">
                  <div>
                    Tradeit.gg
                    <Tooltip title="Tradeit.gg pricing reflects the cost to withdraw containers and trade back unboxed items. Estimated prices, marked in grey, are used when specific item prices are unavailable." sx={{ margin: 'auto' }}>

                      <HelpOutlineIcon className="iconSVG" sx={{ width: isMobileLayout ? '16px' : '20px', height: isMobileLayout ? '16px' : '20px', margin: 'auto', marginLeft: '4px', marginBottom: '-4px' }} fontSize='large' />
                    </Tooltip></div>
                </MenuItem>
                {/* Add more pricing source options if needed */}
              </Select>
            </FormControl>
            {
              pricingSource === "Tradeit" &&
              <FormGroup sx={{ padding: '0 8px 12px 16px', fontSize: '0.8rem' }}>
                <FormControlLabel sx={{ fontSize: '0.8rem' }} control={<Checkbox checked={tradeitHideUnavailable} onClick={() => handleTradeitHide(!tradeitHideUnavailable)} />} label="Show Unavailable" />
              </FormGroup>
            }
            <Autocomplete
              value={currency}
              onChange={(event, newValue) => setCurrency(newValue)}
              disableClearable
              options={currencies}
              getOptionLabel={(option) => option.toUpperCase()}
              sx={{ fontFamily: "'Barlow', 'Roboto', sans-serif", padding: '0 12px 12px 12px' }}
              renderInput={(params) => <TextField {...params} label="Currency" />}
            />

            {/* </div>} */}
            <Divider />
            {!isMobileLayout && items.length > 0 &&
              <p style={{
                margin: '12px',
                textAlign: 'center',
                backgroundColor: "#121212",
                border: 'rgba(255, 255, 255, 0.23) 1px solid',
                color: 'white',
                padding: '.25rem .75rem',
                borderRadius: '0.25rem'
              }} >
                Updated<br />
                {(() => {
                  const updatedAtUTC = items[0]["UpdatedAt"] * 1000;
                  const now = new Date();
                  const timeDifference = now.getTime() - updatedAtUTC;
                  const hoursDifference = Math.floor(timeDifference / (1000 * 60 * 60));
                  const daysDifference = Math.floor(hoursDifference / 24);

                  let message;
                  if (daysDifference > 0) {
                    message = `${daysDifference} day${daysDifference !== 1 ? 's' : ''} ago`;
                  } else {
                    // if (hoursDifference > 0) {
                    message = `${hoursDifference} hour${hoursDifference !== 1 ? 's' : ''} ago`;
                    // } else {
                    //   message = "Less than an hour ago";
                    // }
                  }

                  return message;
                })()}
              </p>


            }

            <Divider />
            <div style={{ margin: '12px', }}>
              <p style={{

                textAlign: 'center',
                backgroundColor: "#121212",
                border: 'rgba(255, 255, 255, 0.23) 1px solid',
                color: 'white',
                padding: '.25rem .75rem',
                borderRadius: '0.25rem', textAlign: "center", margin: "auto", color: "white",
              }}>A Project By Brongo: <br />
                <div style={{ display: 'flex' }}>
                  <a href="https://twitter.com/BrongoCS" target="_blank" style={{ flex: 1 }}><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 1200 1227" fill="none">
                    <path d="M714.163 519.284L1160.89 0H1055.03L667.137 450.887L357.328 0H0L468.492 681.821L0 1226.37H105.866L515.491 750.218L842.672 1226.37H1200L714.137 519.284H714.163ZM569.165 687.828L521.697 619.934L144.011 79.6944H306.615L611.412 515.685L658.88 583.579L1055.08 1150.3H892.476L569.165 687.854V687.828Z" fill="white"></path>
                  </svg></a>
                  <a href="https://www.youtube.com/@BrongoCS" target="_blank" style={{ flex: 1 }}><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 576 512" fill="none"><path fill="white" d="M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z"></path></svg></a>
                  <a href="https://steamcommunity.com/tradeoffer/new/?partner=83942777&amp;token=Z_ZUTuBY" style={{ flex: 1 }}><img src="/assets/steamlogo.png" style={{ width: '20px', height: '20px' }} /></a>
                </div>
              </p>
            </div>
            <Divider />
            <Accordion sx={{ background: '#121212', padding: '4px 16px 4px 16px', margin: 0 }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                key="Float Drop Chances"
                sx={{ padding: '0', backgroundColor: '#121212' }}
              >
                <Typography>Advanced Settings</Typography>

              </AccordionSummary>
              <AccordionDetails sx={{ padding: '0', backgroundColor: '#121212' }}>
                <FormControlLabel control={<Checkbox onClick={() => handleShowUnbox(!showUnbox)} checked={showUnbox} />} label="Show Unboxing ROI" />
                <FormControlLabel control={<Checkbox onClick={() => handleShowProfit(!showProfit)} checked={showProfit} />} label="Show Profit Chance" />
                <FormControlLabel control={<Checkbox onClick={() => handleShowInvest(!showInvest)} checked={showInvest} />} label="Show Investing ROI" />
                <FormControlLabel control={<Checkbox onClick={() => handleShowListings(!showListings)} checked={showListings} />} label="Show Listings" />
                {/* <FloatDropChances caseData={caseData} exchangeRate={exchangeRate} currency={currency} isMobile={isMobileLayout}/> */}
              </AccordionDetails>


            </Accordion>
            <Divider />
            <Divider />

            <div style={{ margin: '12px', display: 'flex', flexDirection: 'row' }} >
              <Link to="faq" style={{ textDecoration: 'none', marginLeft: 'auto', marginRight: 'auto' }}>
                <p style={{

                  textAlign: 'center',
                  backgroundColor: "#121212",
                  border: 'rgba(255, 255, 255, 0.23) 1px solid',
                  color: 'white',
                  padding: '.25rem .75rem',
                  borderRadius: '0.25rem', textAlign: "center", margin: "auto", color: "white", width: '70px', cursor: "pointer",

                }}>FAQ
                </p>
              </Link>
              <Link to="changelog" style={{ textDecoration: 'none', marginLeft: 'auto', marginRight: 'auto' }}>
                <p style={{

                  textAlign: 'center',
                  backgroundColor: "#121212",
                  border: 'rgba(255, 255, 255, 0.23) 1px solid',
                  color: 'white',
                  padding: '.25rem .75rem',
                  borderRadius: '0.25rem', textAlign: "center", margin: "auto", color: "white", width: '120px', cursor: "pointer",
                }}>Change Log
                </p>
              </Link>
            </div>


            <Divider />
            {/* <h5 style={{ textAlign: 'center', maxHeight: '180px', padding: '4px' }}>
              All Key Costs for third-party marketplaces are now automatically adjusted to reflect an easily achievable cost when converting to Steam Market balance.<br/><br/>
              <a href='/#/deals'>Find the best deals to get Steam Market balance here!</a>
            </h5> */}
            {/* <h5 style={{ textAlign: 'center', maxHeight: '180px', padding: '4px' }}>
              Buff.163 has removed all containers from their site. They will continue to be tracked for historical purposes. All container prices using Buff.163 pricing from the 19th of May onwards are now instead using 80% of the Steam Market cost, as most users can convert Buff.163 balance to Steam Market balance at that rate or better.
            </h5> */}
            {/* <Divider /> */}
            {/* <iframe width={drawerWidth} height="180" src="https://www.youtube.com/embed/mMCqwpVC2Zc?si=1DY9BEJKcLe66qy3" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share;" allowFullScreen ></iframe> */}
            <Divider />
          </Scrollbars>
        </Drawer>
        <Box
          component="main"
          sx={{

            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          {/* <Toolbar /> */}
          <Scrollbars
            // autoHide
            // autoHideTimeout={1000}
            // autoHideDuration={200}
            renderThumbVertical={({ style }) => (
              <div style={{ ...style, backgroundColor: 'lightgray', borderRadius: '4px' }} />
            )}
          >



            <Container maxWidth="false" sx={{ mb: 4, mt: "4px" }}>
              <Container style={{ display: 'flex', flexDirection: 'row', padding: '0', margin: '0', maxWidth: 'none', flexWrap: 'wrap', justifyContent: 'center' }}>
                {/* <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={handleDrawerOpen}
                  edge="start"
                  sx={{ mr: 2 }}
                >
                  <MenuIcon />
                </IconButton> */}
                <div style={{ marginTop: '8px', marginBottom: '10px', display: isMobileLayout ? 'flex' : 'none', flexDirection: 'row', marginLeft: 'auto', marginRight: 'auto' }}>
                  <FormControl size={isMobileLayout ? 'small' : 'medium'} sx={{ fontFamily: "'Barlow', 'Roboto', sans-serif", margin: '0 4px 0px 0px', minWidth: '180px', flex: '1', display: 'inline-flex', }}>
                    <InputLabel id="pricing-source-label" >Pricing Source</InputLabel>
                    <Select
                      //  renderInput={(params) => <TextField {...params} label="Pricing Source" />}

                      labelId="pricing-source-label"
                      id="pricing-source"
                      value={pricingSource}
                      style={{ height: isMobileLayout ? '48px' : '56px', fontSize: isMobileLayout ? '0.9rem' : '1rem', }}
                      label="Pricing Source"
                      onChange={handlePricingSourceChange}

                    >
                      <MenuItem dense={isMobileLayout} value="Steam">Steam</MenuItem>
                      <MenuItem dense={isMobileLayout} value="Buff">
                        <div>Buff.163 / UUPin
                          <Tooltip title="Buff.163 prices are preferred, but UUPin prices are used when Buff.163 prices are unavailable (e.g., containers) or when there is a significant price difference." sx={{ margin: 'auto' }}>

                            <HelpOutlineIcon className="iconSVG" sx={{ width: isMobileLayout ? '16px' : '20px', height: isMobileLayout ? '16px' : '20px', margin: 'auto', marginLeft: '4px', marginBottom: '-4px' }} fontSize='large' />
                          </Tooltip>
                        </div>
                      </MenuItem>
                      <MenuItem dense={isMobileLayout} value="CSFloat">CSFloat</MenuItem>
                      <MenuItem dense={isMobileLayout} value="Skinport">Skinport</MenuItem>
                      <MenuItem dense={isMobileLayout} value="Tradeit">
                        <div>
                          Tradeit.gg
                          <Tooltip title="Tradeit.gg pricing reflects the cost to withdraw containers and trade back unboxed items. Estimated prices, marked in grey, are used when specific item prices are unavailable." sx={{ margin: 'auto' }}>

                            <HelpOutlineIcon className="iconSVG" sx={{ width: isMobileLayout ? '16px' : '20px', height: isMobileLayout ? '16px' : '20px', margin: 'auto', marginLeft: '4px', marginBottom: '-4px' }} fontSize='large' />
                          </Tooltip></div>
                      </MenuItem>
                      {/* Add more pricing source options if needed */}
                    </Select>
                  </FormControl>

                  <Autocomplete
                    size={isMobileLayout ? 'small' : 'medium'}
                    value={currency}
                    onChange={(event, newValue) => setCurrency(newValue)}
                    disableClearable
                    options={currencies}
                    getOptionLabel={(option) => option.toUpperCase()}
                    sx={{
                      fontFamily: "'Barlow', 'Roboto', sans-serif",
                      padding: '0 0px 0 0px',
                      flex: '1',
                      height: isMobileLayout ? '48px' : '56px',


                    }}
                    renderOption={(props, option) => (
                      <MenuItem {...props} dense={isMobileLayout}>
                        {option.toUpperCase()}
                      </MenuItem>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Currency"
                        size={isMobileLayout ? 'small' : 'medium'}
                        // Do not use InputProps here; instead, pass everything via slotProps
                        slotProps={{
                          input: {
                            // Spread the underlying <input> props
                            ...params.inputProps,
                            // Attach the ref needed for autocomplete functionality
                            ref: params.InputProps.ref,
                            // Merge styles and override the height
                            style: {
                              ...params.inputProps?.style,
                              height: isMobileLayout ? '48px' : '56px',
                              fontSize: isMobileLayout ? '0.9rem' : '1rem',
                            },
                            // Explicitly pass the adornments so the dropdown arrow is rendered
                            startAdornment: params.InputProps.startAdornment,
                            endAdornment: params.InputProps.endAdornment
                          }
                        }}
                      />
                    )}
                  />




                </div>
                {
                  pricingSource === "Tradeit" && isMobileLayout &&
                  <FormGroup sx={{ fontSize: '0.8rem', marginTop: '-16px' }} >
                    <FormControlLabel sx={{}} control={<Checkbox checked={tradeitHideUnavailable} onClick={() => handleTradeitHide(!tradeitHideUnavailable)} />} label="Show Unavailable Containers" />
                  </FormGroup>
                }
                {/* {
                  isMobileLayout &&
                  <div style={{ marginBottom: '12px', display: 'flex', border: '1px solid rgba(255, 255, 255, 0.23)', borderRadius: '4px', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', padding: '4px 16px 4px 16px', cursor: 'pointer' }} onClick={() => window.location.href = '/#/deals'}>
                    <p style={{ lineHeight: '0.9', marginRight: '4px' }}>
                      Deals
                      <br />
                      <span style={{ fontSize: '0.7rem' }}>Resell Skins to get up to 1.5x Steam Wallet Balance!</span>
                    </p>

                  </div>
                }
                {
                  isMobileLayout &&
                  <div style={{ marginBottom: '4px', display: 'flex', border: '1px solid rgba(255, 255, 255, 0.23)', borderRadius: '4px', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', padding: '4px 16px 4px 16px', cursor: 'pointer' }} onClick={() => window.location.href = '/#/charmcolors'}>
                    <p style={{ lineHeight: '0.9', marginRight: '4px' }}>
                      Charm Colors
                      <br />
                      <span style={{ fontSize: '0.7rem' }}>Find the color and pattern of charm to match your skin!</span>
                    </p>
                    <Chip label="NEW" variant="outlined" size='small' sx={{ color: 'white', border: '1px white solid', fontSize: '0.8rem', height: '24px', width: '48px', padding: '0', marginLeft: '4px' }} />
                  </div>
                } */}
                {
                  isMobileLayout &&
                  <div style={{ display: 'flex', flexDirection: 'row', width: '100%', border: '1px solid rgba(255, 255, 255, 0.23)', borderRadius: '4px', marginBottom: '12px', }}>
                    <Link to='/deals' style={{ flex: 1, textDecoration: 'none', color: 'white' }}>
                      <div style={{ display: 'flex', flexDirection: 'row', flex: 1, justifyContent: 'space-between', alignItems: 'center', padding: '2px', cursor: 'pointer', borderRight: '1px solid rgba(255, 255, 255, 0.23)' }}>
                        <p style={{ lineHeight: '0.9', textAlign: 'center', width: '100%', fontSize: '0.8rem' }}>
                          Deals
                          <br />
                          <span style={{ fontSize: '0.58rem' }}>Get up to 1.5x Steam Wallet Balance!</span>
                        </p>

                      </div>
                    </Link>
                    <Link to='/charmcolors' style={{ flex: 1, textDecoration: 'none', color: 'white' }}>
                      <div style={{ display: 'flex', flexDirection: 'row', flex: 1, justifyContent: 'space-between', alignItems: 'center', padding: '2px', cursor: 'pointer' }}>
                        <p style={{ lineHeight: '0.9', textAlign: 'center', width: '100%', fontSize: '0.8rem' }}>
                          Charm Colors
                          <br />
                          <span style={{ fontSize: '0.58rem' }}>Find the best charm for your skin!</span>
                        </p>
                      </div>
                    </Link>
                  </div>
                }
              </Container>


              <div style={{ height: '95vh', paddingBottom: '12px' }}>
                {/* <Scrollbars
                  autoHide
                  autoHideTimeout={1000} 
                  autoHideDuration={200}
                  renderThumbVertical={({ style }) => (
                    <div style={{ ...style, backgroundColor: 'lightgray', borderRadius: '4px' }} />
                  )}
                > */}



                {/* <Grid container direction="row" spacing={{}} columns={{ xs: 20, sm: 20, md: 20, lg: 20 }} sx={{ flex: 6, flexWrap: 'wrap', justifyContent: 'center', padding: 0, maxWidth: '100%' }}> */}


                {/* {items.map((item, index) => {
                      return <div style={{ margin: '2px', flex: 6, flexWrap: 'wrap', justifyContent: 'center', padding: 0, maxWidth: '800px' }}>{CaseGridItem(item, index, pricingSource, currency, exchangeRate, sortBy, isMobileLayout, showUnbox, showProfit, showInvest)}</div>
                      
                    })} */}
                {/* {items.map((item, index) => (
                      <div style={{ margin: '2px', flex: 6, flexWrap: 'wrap', justifyContent: 'center', padding: 0, maxWidth: '800px' }}>
                        <CaseGridItem
                          key={item.id}
                          item={item}
                          index={index}
                          pricingSource={pricingSource}
                          currency={currency}
                          exchangeRate={exchangeRate}
                          sortBy={sortBy}
                          isMobileLayout={isMobileLayout}
                          showUnbox={showUnbox}
                          showProfit={showProfit}
                          showInvest={showInvest}
                        />
                      </div>
                    ))} */}

                {/* <AutoSizer>
                  {({ height, width }) => {
                    let minColumnWidth = width < 380 ? 240 : 380;
                    let columnCount = Math.floor(width / minColumnWidth);
                    let columnWidth = (width - 10) / columnCount;

                    return (
                      <ColumnCountContext.Provider value={columnCount}>
                        <FixedSizeGrid
                          columnCount={columnCount}
                          columnWidth={columnWidth}
                          height={height}
                          rowCount={Math.ceil(items.length / columnCount)}
                          rowHeight={isMobileLayout ? 250: 240} // adjust this to your needs
                          width={width}
                          style={{ margin: 'auto' }}
                          align="center"
                        >
                          {Cell}
                        </FixedSizeGrid>
                      </ColumnCountContext.Provider>
                    );
                  }}
                </AutoSizer> */}
                {/* </Grid> */}
                {/* </Scrollbars> */}
                <CarePackageBox carePackageData={carePackageData} formatNumber={formatNumber} exchangeRate={exchangeRate} delay={delay} />
                {/* <Button style={{ margin: 'auto' }} onClick={() => simulateCarePackage()}>
                  Simulate
                </Button> */}
                {isMobileLayout ? <div style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                  <div style={{ margin: 'auto', paddingBottom: '12px', justifyContent: 'center' }}>
                    <Button variant="contained" onClick={simulateCarePackage} style={{ display: filterType === 'None' ? 'initial' : 'none', backgroundColor: '#bb171a', color: 'white', fontSize: '1.1rem', paddingLeft: '12px', paddingRight: '12px' }}>Simulate</Button>
                    <Button variant="contained" onClick={simulateXTimes} style={{ display: filterType === 'XTimes' ? 'initial' : 'none', backgroundColor: '#bb171a', color: 'white', fontSize: '1.1rem', paddingLeft: '12px', paddingRight: '12px' }}>Simulate X Times</Button>
                    {/* <p style={{ display: filterType === 'Filter' ? 'block' : 'none', textAlign: 'center' }}><Button variant="contained" onClick={simulateUntilFilter} style={{ backgroundColor: '#bb171a', color: 'white', fontSize: '1.1rem', paddingLeft: '12px', paddingRight: '12px' }}>Simulate Until Desired Item</Button><br />1 in {((1 / (caseData["Items"]?.[itemsFilter]?.["ModifiedDropChance"] ?? 1)) / ((raritiesFilter != '' ? caseData['PercentReceive'][raritiesFilter] : 1) / (itemsFilter != '' ? (((raritiesFilter.includes("Contraband") ? caseData["TotalKnifeChance"] : Object.keys(caseData['ItemCosts'][raritiesFilter]).length))) : 1) * (wearsFilter != '' ? ((wearsFilterChances[realWearNames[wearsFilter.replace('StatTrak™ ', '')]] * (wearsFilter.includes('StatTrak™') ? 0.1 : 0.9))) : 1))).toFixed(1)} Unbox</p> */}
                  </div>
                  <div style={{ width: '100%', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', alignSelf: 'center', marginBottom: '8px', }}>
                    <FormControl style={{ width: '200px', marginRight: '10px' }}>
                      <InputLabel id="demo-simple-select-label">Repeat Simulations</InputLabel>
                      <Select
                        value={filterType}
                        label="Repeat Simulations"
                        onChange={(event) => {
                          setFilterType(event.target.value);
                        }}
                      >
                        <MenuItem value="None">None</MenuItem>
                        <MenuItem value="XTimes">X Times</MenuItem>
                        <MenuItem value="Filter">Until Filter</MenuItem>
                        <MenuItem value="Worth">Until Worth</MenuItem>
                      </Select>
                    </FormControl>
                    {filterType == "Worth" && <TextField id="outlined-basic" label="Delay (s)" variant="outlined" value={delay} type="number" onChange={(event) => {
                      setDelay(event.target.value);
                    }} style={{ width: '200px' }} />}
                    <TextField id="outlined-basic" label="Delay (s)" variant="outlined" value={delay} type="number" onChange={(event) => {
                      setDelay(event.target.value);
                    }} style={{ width: '200px' }} />
                  </div></div> : <div style={{ width: '100%', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', alignSelf: 'center', marginBottom: '8px', }}>
                  <FormControl style={{ width: '200px', marginRight: '10px' }}>
                    <InputLabel id="demo-simple-select-label">Repeat Simulations</InputLabel>
                    <Select
                      value={filterType}
                      label="Repeat Simulations"
                      onChange={(event) => {
                        setFilterType(event.target.value);
                      }}
                    >
                      <MenuItem value="None">None</MenuItem>
                      <MenuItem value="XTimes">X Times</MenuItem>
                      <MenuItem value="Filter">Until Filter</MenuItem>
                      <MenuItem value="Worth">Until Worth</MenuItem>
                    </Select>
                  </FormControl>
                  <Button variant="contained" onClick={simulateCarePackage} style={{ display: filterType === 'None' ? 'initial' : 'none', backgroundColor: '#bb171a', color: 'white' }}>Simulate</Button>
                  {/* <p style={{ display: filterType === 'Profit' ? 'initial' : 'none', textAlign: 'center' }}><Button variant="contained" onClick={simulateUntilProfit} style={{ backgroundColor: '#bb171a', color: 'white' }}>Simulate Until A Profit</Button><br />1 in {(1 / caseData['Profit']).toFixed(1)} Unbox</p>*/}
                  <Button variant="contained" onClick={simulateXTimes} style={{ display: filterType === 'XTimes' ? 'initial' : 'none', backgroundColor: '#bb171a', color: 'white' }}>Simulate X Times</Button>
                  <Button variant="contained" onClick={simulateXMoney} style={{ display: filterType === 'Worth' ? 'initial' : 'none', backgroundColor: '#bb171a', color: 'white' }}>Simulate Until</Button>
                  
                  <p style={{ display: filterType === 'Filter' ? 'initial' : 'none', textAlign: 'center' }}><Button variant="contained" onClick={simulateUntilFilter} style={{ backgroundColor: '#bb171a', color: 'white' }}>Simulate Until Desired Item</Button><br />1 in {formatNumber((1 / ((collectionData[collectionFilter]?.["Items"]?.[itemsFilter]?.["ModifiedDropChance"] ?? 1) / Object.keys(collectionData).length)) / ((raritiesFilter != '' ? collectionData[collectionFilter]?.['PercentReceive'][raritiesFilter] : 1) / (itemsFilter != '' ? (((raritiesFilter.includes("Contraband") ? collectionData[collectionFilter]?.["TotalKnifeChance"] : Object.keys(collectionData[collectionFilter]?.['ItemCosts'][raritiesFilter]).length))) : 1) * (wearsFilter != '' ? ((wearsFilterChances[wearsFilter.replace('StatTrak™ ', '')] * (wearsFilter.includes('StatTrak™') ? 0.1 : 0.9))) : 1)))} Unbox</p>
                  {filterType == "Worth" && <TextField id="outlined-basic" label={"Worth more than " + currencySymbol} variant="outlined" value={worth} type="number" onChange={(event) => {
                      setWorth(event.target.value);
                    }} style={{ width: '200px' }} />}
                  <TextField id="outlined-basic" label="Delay (s)" variant="outlined" value={delay} type="number" onChange={(event) => {
                    setDelay(event.target.value);
                  }} style={{ width: '200px' }} />
                </div>}
                <div style={{ width: '100%', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', alignSelf: 'center', flexWrap: 'wrap' }}>
                  <FormControl sx={{ m: 1, width: 300, display: filterType === 'Filter' ? 'inherit' : 'none' }}>
                    <InputLabel id="demo-single-select-label">Collection</InputLabel>
                    <Select
                      value={collectionFilter}
                      onChange={(event) => setCollection(event)}
                      input={<OutlinedInput label="Collection" />}
                      renderValue={(selected) => (collectionData[selected].Case + " - " + (1 / Object.keys(collectionData).length * 100).toFixed(2) + "%")}
                    >
                      {collectionData && Object.keys(collectionData).length > 0 ? (
                        Object.entries(collectionData).map(([id, data]) => (
                          <MenuItem key={data.Case} value={id}>
                            <ListItemText primary={data.Case + " - " + (1 / Object.keys(collectionData).length * 100).toFixed(2) + "%"} />
                          </MenuItem>
                        ))
                      ) : null}
                    </Select>
                  </FormControl>
                  <FormControl sx={{ m: 1, width: 300, display: filterType === 'Filter' ? 'inherit' : 'none' }}>
                    <InputLabel id="demo-single-select-label">Rarity</InputLabel>
                    <Select
                      value={raritiesFilter}
                      onChange={(event) => setRarities(event)}
                      input={<OutlinedInput label="Rarity" />}
                      renderValue={(selected) => (selected.replace('_', '-').replace('Contraband', 'Gloves/Knives') + " - " + (collectionData[collectionFilter]["PercentReceive"][selected] * 100).toFixed(2) + "%")}
                    >
                      {collectionData && collectionFilter && Object.keys(collectionData).length > 0 ? (
                        Object.entries(collectionData[collectionFilter]["PercentReceive"]).map(([name, chance]) => (
                          <MenuItem key={name} value={name}>
                            <ListItemText primary={name.replace('_', '-').replace('Contraband', 'Gloves/Knives') + " - " + (collectionData[collectionFilter]["PercentReceive"][name] * 100).toFixed(2) + "%"} />
                          </MenuItem>
                        ))
                      ) : null}
                    </Select>
                  </FormControl>
                  <FormControl sx={{ m: 1, width: 500, display: filterType === 'Filter' ? 'inherit' : 'none' }}>
                    <InputLabel id="demo-single-select-label">Item</InputLabel>
                    <Select
                      labelId="demo-single-select-label"
                      id="demo-single-select"
                      value={itemsFilter}
                      onChange={(event) => setItems(event)}
                      input={<OutlinedInput label="Item" />}
                    >
                      {raritiesFilter !== '' && collectionFilter !== '' ? (
                        Object.entries(collectionData[collectionFilter]["ItemCosts"][raritiesFilter]) // Filter items by the selected rarity
                          .sort(([, a], [, b]) => b.totalItemValue - a.totalItemValue) // Sort by totalItemValue
                          .map(([item, data]) => ( // Destructure key-value pairs
                            <MenuItem key={item} value={item} style={{ minHeight: 'auto', display: 'flex', alignItems: 'center' }}>
                              <img src={collectionData[collectionFilter]['Items'][item]['ImageUrl']} alt={item} style={{ width: '64px' }} />
                              <ListItemText
                                style={{ textAlign: 'center' }}
                                primary={`${item.replace('Sticker | ', '')} - ${formatNumber(data.totalItemValue / exchangeRate, true)} AVG`}
                              />
                            </MenuItem>
                          ))
                      ) : null}
                    </Select>
                  </FormControl>
                  <FormControl sx={{ m: 1, width: 300, display: filterType === 'Filter' ? 'inherit' : 'none' }}>
                    <InputLabel id="demo-single-select-label">Wear</InputLabel>
                    <Select
                      labelId="demo-single-select-label"
                      id="demo-single-select"
                      value={wearsFilter}
                      onChange={(event) => setWearsFilter(event.target.value)}
                      input={<OutlinedInput label="Wear" />}
                      renderValue={(selected) =>
                        `${smallWearNames[selected]} (${formatNumber(dropItems.find(item => item.Name === itemsFilter)["Cost"][wearCodeNames[selected]] / exchangeRate, true)}) - ${(wearsFilterChances[selected] * 100).toFixed(2)}%`
                      }
                    >
                      {itemsFilter !== '' ? (
                        Object.entries(dropItems.find(item => item.Name === itemsFilter)?.WearChances || {}).map(([name, percent]) => (
                          name !== "totalItemValue" && (
                            <MenuItem key={name} value={name}>
                              <ListItemText
                                primary={`${smallWearNames[name]} - ${(percent * 100).toFixed(2)}%`}
                                secondary={formatNumber(dropItems.find(item => item.Name === itemsFilter)["Cost"][wearCodeNames[name]] / exchangeRate, true)}
                              />
                            </MenuItem>
                          )
                        ))
                      ) : null}
                    </Select>
                  </FormControl>
                  <TextField
                    id="outlined-basic"
                    label="Repeats"
                    variant="outlined"
                    value={numRepeats}
                    type="number"
                    onChange={(event) => setNumRepeats(Number(event.target.value))}
                    style={{ width: '200px', margin: '8px', display: filterType === 'XTimes' ? 'inherit' : 'none' }}
                  />
                </div>
                <h2>AVG CARE PACKAGE: {formatNumber(expectedReturn / exchangeRate, true)}</h2>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: '4px 16px 4px 16px', cursor: 'pointer' }}>
                  <div style={{ height: '2180px', paddingBottom: '12px', flex: '5' }}>
                    <div style={{ height: '1280px' }}>
                      <h2 style={{ margin: 'auto', textAlign: 'center' }}>Active Cases</h2>
                      <h3 style={{ margin: 'auto', textAlign: 'center', lineHeight: '1' }}> 99% ({formatNumber((commonCases.length > 0
                        ? commonCases.reduce((sum, item) => {
                          const collectionPriceKey = `CollectionPrice${pricingSource}`;
                          return sum + (item[collectionPriceKey] || 0); // Add the price if it exists, otherwise add 0
                        }, 0) / commonCases.length
                        : 0) / exchangeRate, true)} AVG)</h3>
                      <AutoSizer>
                        {({ height, width }) => {
                          let minColumnWidth = width < 320 ? 200 : 320;
                          let columnCount = Math.floor(width / minColumnWidth);
                          let columnWidth = index => (width - 20) / columnCount;
                          let rowHeight = index => (isMobileLayout ? 254 : 244); // Set a larger height for the first row

                          return (
                            <ColumnCountContext.Provider value={columnCount}>
                              <Grid
                                columnCount={columnCount}
                                columnWidth={columnWidth}
                                height={height}
                                rowCount={Math.ceil(commonCases.length / columnCount)} // Add 1 to the row count for the div
                                rowHeight={rowHeight}
                                width={width}
                                style={{ margin: 'auto' }}
                                itemData={commonCases} // Pass `items` through itemData
                              >
                                {({ columnIndex, rowIndex, style }) => (
                                  <Cell columnIndex={columnIndex} rowIndex={rowIndex} style={style} items={commonCases} isCommon={true} />
                                )}
                              </Grid>
                            </ColumnCountContext.Provider>
                          );
                        }}
                      </AutoSizer>
                    </div>
                    <div style={{ height: '900px', paddingBottom: '12px', flex: '4' }}>
                      <h2 style={{ margin: 'auto', textAlign: 'center' }}>Rare Cases</h2>
                      <h3 style={{ margin: 'auto', textAlign: 'center', lineHeight: '1' }}>1% ({formatNumber((rareCases.length > 0
                        ? rareCases.reduce((sum, item) => {
                          const collectionPriceKey = `CollectionPrice${pricingSource}`;
                          return sum + (item[collectionPriceKey] || 0); // Add the price if it exists, otherwise add 0
                        }, 0) / rareCases.length
                        : 0) / exchangeRate, true)} AVG)</h3>
                      <AutoSizer>
                        {({ height, width }) => {
                          let minColumnWidth = width < 320 ? 200 : 320;
                          let columnCount = Math.floor(width / minColumnWidth);
                          let columnWidth = index => (width - 20) / columnCount;
                          let rowHeight = index => (isMobileLayout ? 254 : 244); // Set a larger height for the first row

                          return (
                            <ColumnCountContext.Provider value={columnCount}>
                              <Grid
                                key="Rare Cases"
                                columnCount={columnCount}
                                columnWidth={columnWidth}
                                height={height - 50}
                                rowCount={Math.ceil(rareCases.length / columnCount)} // Add 1 to the row count for the div
                                rowHeight={rowHeight}
                                width={width}
                                style={{ margin: 'auto' }}
                                itemData={rareCases} // Pass `items` through itemData
                                itemKey={({ columnIndex, rowIndex, data }) =>
                                  data[rowIndex * columnCount + columnIndex]?.Name ?? `empty-${rowIndex}-${columnIndex}`}
                              >
                                {({ columnIndex, rowIndex, style }) => (
                                  <Cell columnIndex={columnIndex} rowIndex={rowIndex} style={style} items={rareCases} />
                                )}
                              </Grid>
                            </ColumnCountContext.Provider>
                          );
                        }}
                      </AutoSizer>
                    </div>
                    {/* <div style={{ height: '600px' }}>
                  <h2>Collections ({formatNumber((collections.length > 0
                    ? collections.reduce((sum, item) => {
                      const collectionPriceKey = `${pricingSource}ROI`;
                      return sum + (item[collectionPriceKey] || 0)/100; // Add the price if it exists, otherwise add 0
                    }, 0) / collections.length
                    : 0) / exchangeRate, true)} AVG)</h2>
                  <AutoSizer>
                    {({ height, width }) => {
                      let minColumnWidth = width < 320 ? 200 : 320;
                      let columnCount = Math.floor(width / minColumnWidth);
                      let columnWidth = index => (width - 10) / columnCount;
                      let rowHeight = index => (isMobileLayout ? 254 : 244); // Set a larger height for the first row

                      return (
                        <ColumnCountContext.Provider value={columnCount}>
                          <Grid
                            key="Collections"
                            columnCount={columnCount}
                            columnWidth={columnWidth}
                            height={height}
                            rowCount={Math.ceil(collections.length / columnCount)} // Add 1 to the row count for the div
                            rowHeight={rowHeight}
                            width={width}
                            style={{ margin: 'auto' }}
                            itemData={collections} // Pass `items` through itemData
                          >
                            {({ columnIndex, rowIndex, style }) => (
                              <Cell columnIndex={columnIndex} rowIndex={rowIndex} style={style} items={collections} />
                            )}
                          </Grid>
                        </ColumnCountContext.Provider>
                      );
                    }}
                  </AutoSizer>
                </div> */}

                  </div>

                  <div style={{ height: '2129px', paddingBottom: '12px', flex: '14', marginTop: '0' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly' }}>
                      <div>
                        <h2 style={{ margin: 'auto', textAlign: 'center', marginTop: '0' }}>Active Drops</h2>
                        <h3 style={{ margin: 'auto', textAlign: 'center', lineHeight: '1' }}> ({formatNumber((activeDropValue) / exchangeRate, true)} AVG)</h3>
                      </div>
                      <Autocomplete
                        inputValue={inputValue}
                        onInputChange={handleInputChange}
                        onChange={handleSearchChange}
                        // onBlur={handleBlur}
                        // onClose={handleClose}
                        // size='small'                  
                        freeSolo
                        disablePortal
                        id="combo-box-demo"
                        options={dropItems}
                        filterOptions={(items, { inputValue }) => {
                          const searchTerms = inputValue.toLowerCase().split(' ').filter(term => term);
                          return dropItems.filter((option) => {
                            const normalizedOption = option?.Name.toLowerCase();
                            return searchTerms.every(term => normalizedOption.includes(term));
                          });
                        }}
                        getOptionLabel={(option) => option?.Name ?? option}
                        sx={{ width: '400px', marginBottom: isMobileLayout ? 'auto' : 'auto', marginTop: isMobileLayout ? '10px' : 'auto', }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Search"
                            InputProps={{
                              ...params.InputProps,
                              type: 'search',
                            }}
                          />
                        )}
                      />
                    </div>
                    <AutoSizer>
                      {({ height, width }) => {
                        let minColumnWidth = 265;
                        let columnCount = Math.max(2, Math.floor(width / minColumnWidth));
                        let columnWidth = (width - 20) / columnCount; // Fixed width calculation
                        let rowHeight = 230; // Avoid dynamic heights for testing

                        return (
                          <Grid
                            columnCount={columnCount}
                            columnWidth={() => columnWidth} // Make it static
                            height={height}
                            rowCount={Math.ceil(dropItems.length / columnCount)}
                            rowHeight={() => rowHeight} // Make it static
                            width={width}
                            itemData={dropItems}
                            style={{ margin: 'auto' }}
                            itemKey={({ columnIndex, rowIndex, data }) =>
                              data[rowIndex * columnCount + columnIndex]?.name ?? `empty-${rowIndex}-${columnIndex}`}
                          >
                            {({ columnIndex, rowIndex, style, data }) => {
                              const item = data[rowIndex * columnCount + columnIndex];
                              if (!item) return null; // Handle empty cells

                              return (
                                <div style={{
                                  ...style,

                                  '&:hover': {
                                    boxShadow: '0 8px 16px 0 rgba(0,0,0,0.6)'
                                  },
                                  borderRadius: 0
                                  // border: '1px solid white',
                                }}>
                                  <Card key={item.name} style={{ height: '96%', margin: "4px", cursor: 'pointer', }} onClick={() => handleItemClick(item)} >
                                    <Card style={{
                                      background: `linear-gradient(145deg, RGB(${redRarityNums[item.Rarity]}, ${greenRarityNums[item.Rarity]}, ${blueRarityNums[item.Rarity]}) 0%, black 100%)`, paddingLeft: 0
                                    }}>
                                      <CardMedia
                                        sx={{ height: 140, backgroundSize: "contain", }}
                                        image={item["ImageUrl"]}
                                      />
                                      <div style={{ position: 'absolute', top: isMobileLayout ? '0' : '0px', left: isMobileLayout ? '4px' : '8px', padding: '4px', borderRadius: '4px 0 0 0', zIndex: 2, fontSize: isMobileLayout ? '16px' : '19px' }}>
                                        <span>1 in {formatNumber(1 / (item["ItemChance"]))}</span>
                                      </div>


                                    </Card>
                                    <div style={{ paddingLeft: '8px', display: 'flex', flex: '1', height: '30%', marginTop: isMobileLayout ? item?.name?.replace("★ (Vanilla)", " Vanilla")?.split(' | ')?.[1]?.split(' (').length > 1 ? '-4px' : '-16px' : '0', flexDirection: isMobileLayout ? 'column-reverse' : 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                                      <div>
                                        <h4 style={{ lineHeight: 1.2, margin: '0', marginTop: isMobileLayout ? '0.4rem' : '0.8rem', textAlign: isMobileLayout ? 'center' : 'left' }}>{item.name?.replace("★ (Vanilla)", " Vanilla").split(' | ')[0]}</h4>
                                        <h3 style={{ lineHeight: 1.2, margin: '0', textAlign: isMobileLayout ? 'center' : 'left', maxWidth: '230px', maxHeight: '2lh', overflow: 'clip' }}>{item.name?.replace("★ (Vanilla)", " Vanilla")?.split(' | ')?.[1]?.split(' (')?.[0]}</h3>
                                        {item.name?.replace("★ (Vanilla)", " Vanilla")?.split(' | ')?.[1]?.split(' (').length > 1 && <h4 style={{ lineHeight: 1.2, margin: '0', textAlign: isMobileLayout ? 'center' : 'left', marginBottom: '4px' }}>{"(" + item.name?.replace("★ (Vanilla)", " Vanilla")?.split(' | ')?.[1]?.split(' (')?.[1]}</h4>}
                                      </div>
                                      <div style={{
                                        backgroundColor: isMobileLayout ? 'rgba(0, 0, 0, 0.5)' : 'transparent',
                                        marginTop: 'auto', justifyContent: 'center', display: 'flex', flexDirection: 'column', marginBottom: 'auto', padding: '4px', borderRadius: '4px 4px 4px 4px', zIndex: 2, fontSize: isMobileLayout ? '16px' : '22px',
                                        //  color:
                                        // caseData?.ISTradeit ? (item.Rarity === "Contraband" ?
                                        //   (typeof caseData?.KnifeEstimates?.[item.name] === "object" ?
                                        //     (Object.entries(caseData?.KnifeEstimates?.[item.name]).filter(([key, value]) => value === true && key.includes("StatTrak")).length * 0.1 +
                                        //       Object.entries(caseData?.KnifeEstimates?.[item.name]).filter(([key, value]) => value === true && !key.includes("StatTrak")).length <
                                        //       Object.entries(caseData?.KnifeEstimates?.[item.name]).filter(([key, value]) => value === false && key.includes("StatTrak")).length * 0.1 +
                                        //       Object.entries(caseData?.KnifeEstimates?.[item.name]).filter(([key, value]) => value === false && !key.includes("StatTrak")).length) :
                                        //     (caseData?.KnifeEstimates?.[item.name] === false)
                                        //   ) ? "white" : "rgb(131 131 131)" :
                                        //   (typeof caseData?.ItemEstimates?.[item.Rarity]?.[item.name] === "object" ?
                                        //     (Object.entries(caseData?.ItemEstimates?.[item.Rarity]?.[item.name]).filter(([key, value]) => value === true && key.includes("StatTrak")).length * 0.1 +
                                        //       Object.entries(caseData?.ItemEstimates?.[item.Rarity]?.[item.name]).filter(([key, value]) => value === true && !key.includes("StatTrak")).length <
                                        //       Object.entries(caseData?.ItemEstimates?.[item.Rarity]?.[item.name]).filter(([key, value]) => value === false && key.includes("StatTrak")).length * 0.1 +
                                        //       Object.entries(caseData?.ItemEstimates?.[item.Rarity]?.[item.name]).filter(([key, value]) => value === false && !key.includes("StatTrak")).length) :
                                        //     (caseData?.ItemEstimates?.[item.Rarity]?.[item.name] === false)
                                        //   ) ? "white" : "rgb(131 131 131)"
                                        // ) : "white"
                                      }}>
                                        {!isMobileLayout && <div style={{ display: 'flex', fontSize: isMobileLayout ? '12px' : '14px', lineHeight: '0.4', marginTop: 'auto', marginBottom: 'auto', padding: '4px', flexDirection: 'row', justifyContent: "center", alignItems: 'center', marginBottom: '-12px', color: item.ROI > -0.01 ? "green" : "rgb(187, 23, 26)" }}>{item.ROI > -0.01 ? <TrendingUpIcon style={{ width: isMobileLayout ? "18px" : '22px', height: isMobileLayout ? "14px" : "17px" }} /> : <TrendingDownIcon style={{ width: isMobileLayout ? "18px" : '22px', height: isMobileLayout ? "14px" : "17px" }} />}{(item?.ROI ?? 0) > -0.01 ? "+" : "-"}{formatNumber(Math.abs(Math.round(item.ROI)), false)}%</div>}
                                        <span style={{ margin: "auto" }}>{formatNumber(item["Cost"]["totalItemValue"] / exchangeRate, true)}</span>
                                        {isMobileLayout && <div style={{ display: 'flex', fontSize: isMobileLayout ? '12px' : '14px', lineHeight: '0.4', marginTop: 'auto', marginBottom: 'auto', padding: '4px', flexDirection: 'row', justifyContent: "center", alignItems: 'center', marginTop: '-10px' }}>{item.ROI > -0.01 ? <TrendingUpIcon style={{ width: isMobileLayout ? "18px" : '22px', height: isMobileLayout ? "14px" : "17px" }} /> : <TrendingDownIcon style={{ width: isMobileLayout ? "18px" : '22px', height: isMobileLayout ? "14px" : "17px" }} />}{(item?.ROI ?? 0) > -0.01 ? "+" : "-"}{formatNumber(Math.abs(Math.round(item.ROI)), false)}%</div>}
                                      </div>
                                    </div>
                                  </Card>
                                </div>
                              );
                            }}
                          </Grid>
                        );
                      }}
                    </AutoSizer>


                  </div>
                </div>
                <p style={{ textAlign: 'center', fontSize: isMobileLayout ? '0.8rem' : '0.9rem' }}>
                  All containers, items and their corresponding images shown on this site are the property of Valve Corporation. CSROI.com does not guarantee that all probability calculations are correct as Valve Corporation controls all unboxing odds, this site serves merely to estimate the odds with community known data. Investing ROI serves only as an estimate based on historical data used on this site and should not be considered investing advice, past performance is not indicative of future performance. Some links on this website use affiliate systems that can earn a small affiliate commission for csroi.com.<br /><br />©2022 - 2025 CSROI.com<br /><br /><a href="http://www.onlinewebfonts.com">Unboxing Icon Provided by Online Web Fonts</a></p>

              </div>
              {isMobileLayout && items.length > 0 &&
                <p style={{
                  margin: 'auto',
                  textAlign: 'center',
                  backgroundColor: "#121212",
                  border: 'rgba(255, 255, 255, 0.23) 1px solid',
                  color: 'white',
                  padding: '.25rem .75rem',
                  borderRadius: '0.25rem',
                  marginTop: '20px',
                }} >
                  Updated<br />
                  {(() => {
                    const updatedAtUTC = items[0]["UpdatedAt"] * 1000;
                    const now = new Date();
                    const timeDifference = now.getTime() - updatedAtUTC;
                    const hoursDifference = Math.floor(timeDifference / (1000 * 60 * 60));
                    const daysDifference = Math.floor(hoursDifference / 24);

                    let message;
                    if (daysDifference > 0) {
                      message = `${daysDifference} day${daysDifference !== 1 ? 's' : ''} ago`;
                    } else {
                      if (hoursDifference > 0) {
                        message = `${hoursDifference} hour${hoursDifference !== 1 ? 's' : ''} ago`;
                      } else {
                        message = "Less than an hour ago";
                      }
                    }

                    return message;
                  })()}
                </p>
              }

              {/* <br/> */}

            </Container>
          </Scrollbars>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

function removeSlashSection(imageUrl) {
  return imageUrl.replace(/\/\d+fx\d+f$/, '/');
}

export default function CarePackagePage() {
  return <CarePackage />;
}

